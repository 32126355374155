import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  capitalize,
  date_time_format,
  updateTimeFormat,
} from "../../../../../../Supporting files/HelpingFunction";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import "../../OrganisationSettings/Currencies/Currencies.scss";
import "./../../../../../../index.scss";
import { RootState } from "../../../../../../Store/Store";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const Users = () => {
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");
  const [useData, setUserData] = useState<any[]>([]);
  const [arraySelect, setArraySelect] = useState<Array<{ id: number }>>([]);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const navigate = useNavigate();
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const { companyCode } = useAppSelector((state) => state.userDetailsSlice);
  const [userCreatePermission, setUserCreatePermission] = useState(false);
  const [userEiditPermission, setUserEiditPermission] = useState(false);
  const [userRemovePermission, setUserRemovePermission] = useState(false);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  useEffect(() => {
    setUserCreatePermission(
      !EMPLOYER
        ? true
        : EMPLOYER.Users.add === undefined
        ? false
        : EMPLOYER.Users.add
    );
    setUserEiditPermission(
      !EMPLOYER
        ? true
        : EMPLOYER.Users.edit === undefined
        ? false
        : EMPLOYER.Users.edit
    );
    setUserRemovePermission(
      !EMPLOYER
        ? true
        : EMPLOYER.Users.remove === undefined
        ? false
        : EMPLOYER.Users.remove
    );
    // get_status(
    //   !EMPLOYER
    //     ? true
    //     : EMPLOYER.Users.remove === undefined
    //     ? false
    //     : EMPLOYER.Users.remove
    // );
    get_status(
      EMPLOYER?.Users?.status === undefined ? false : EMPLOYER?.Users?.status
    );
  }, []);

  //Table Data
  const [columns, setColumns] = useState<any>([]);

  const get_status = (statusPermissions: any) => {
    console.log("statusPermission", statusPermissions);
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);

      setColumns([
        {
          field: "userid",
          headerName: "User ID",
          width: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row.companyCode}</p>;
          },
        },
        {
          field: "email",
          headerName: "Email",
          width: 250,

          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                href={`mailto:${row.email}`}
              >
                {row.email}
              </Link>
            );
          },
        },
        {
          field: "fullname",
          headerName: "Full Name",
          width: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                // style={{
                //   cursor: "pointer",
                //   fontWeight: "400",
                //   textDecoration: "none",
                // }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.EDIT_USER}?id=${row._id}`);
                }}
              >
                {row.name}
              </Link>
            );
          },
        },
        {
          field: "role",
          headerName: "Role",
          width: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.employer_role?.roleName}</p>;
          },
        },
        {
          field: "department",
          headerName: "Department",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.department}</p>;
          },
        },        {
          field: "employer_designation",
          headerName: "Designation",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{capitalize(row?.employer_designation)}</p>;
          },
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.employer_active_status}`}>
                <div
                  className="status_dropdown"
                  id={`_${row.employer_active_status}`}
                >
                  {row.employer_active_status === 13 ? (
                    <GridCheckCircleIcon
                      id={`_${row.employer_active_status.toString()}`}
                    />
                  ) : row.employer_active_status === 14 ? (
                    <HighlightOffIcon
                      id={`_${row.employer_active_status.toString()}`}
                    />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.employer_active_status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPermissions}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "creationdate",
          headerName: "Created on",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.createdAt, user_type_dateFormat, tz)}</p>
            );
          },
        },
        {
          field: "updatedDate",
          headerName: "Updated On",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.updatedAt, user_type_dateFormat, tz)}</p>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status(onSuccess, onError);
  };
  //  get user data
  useEffect(() => {
    get_users();
  }, [deleteStatus]);

  const delete_employee = (attrName?: any, step?: any) => {
    // success delete
    let ids = {
      ids: arraySelect,
    };

    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    ACCESS_LEVEL_SERVICES.delete_user(ids, onSuccess, {}, onError);
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const get_users = () => {
    const onSuccess = (res: any) => {
      let data: any[] = [];
      res.data.data.map((e: any) => {
        data.push({
          ...e,
          id: e._id,
        });
      });
      setUserData(data);
    };
    const onError = (err: any) => {};
    ACCESS_LEVEL_SERVICES.getEmployerUser(onSuccess, onError);
  };

  const edit_navigate = () => {
    if (arraySelect.length === 0) {
      setEditPopupTitle("Please select the user for edit");
      setEditStatus(true);
      return;
    } else if (arraySelect.length > 1) {
      setEditPopupTitle("Only one user allow at a time");
      setEditStatus(true);
      return;
    } else {
      navigate(`${ALL_ROUTES.EDIT_USER}?id=${arraySelect[0]}`);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArraySelect(value);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      update_status(value, attrName[1]._id);
    } else {
      attrName(value);
    }
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_users();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.employer_update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  return (
    <Grid container spacing={2} className="currunciesWrapper">
      <Grid xs={4}>
        <h3 className="mb-0">Users</h3>
      </Grid>

      <Grid xs={8} className="text-right">
        <div className="buttons">
          {userCreatePermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.CREATE_USER);
              }}
            >
              Create New
            </Link>
          )}
          {userEiditPermission && (
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {/* {userRemovePermission && (
            <Link
              underline="none"
              className="redBtn delete"
              onClick={() => {
                setDeleteStatus(true);
              }}
            >
              Remove
            </Link>
          )} */}
        </div>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: 400 }}>
          <UGTable
            header_data={columns}
            data={useData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.USERS}
            class_Name={"th-setting"}
            paginationMode="client"
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            refetch_data={get_users}

          />
        </div>
      </Grid>
      <GlobalDeletePopup
        open_status={deleteStatus}
        value_update={updateMasterState}
        attrName={setDeleteStatus}
        delete_step={deleteStatusStep}
        delete_item={() => {
          delete_employee("data", deleteStatusStep);
        }}
        ids={arraySelect}
        title="User Data"
      />
      <GlobelEditPopup
        popupStatus={editStatus}
        setPopupStatus={setEditStatus}
        title={editPopupTitle}
      />
    </Grid>
  );
};

export default Users;
