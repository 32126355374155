import { Box, Button, Link, Modal, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../../Services/EmployerServices/Employee/EmployeeServices";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import {
  capitalize,
  convertSingleBracketToCurrencyFormat,
  date_time_format,
  getCurrencyCodeRegex,
} from "../../../../Supporting files/HelpingFunction";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
import Employed from "@mui/icons-material/Badge";
import Active from "@mui/icons-material/CheckCircle";
import Terminated from "@mui/icons-material/DirectionsRun";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import { EMPLOYEE_SERVICES } from "../../../../Services/Employee/EmployeeService";
import { toast } from "react-toastify";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import { TUserData } from "../../../../Services/Types/TUserData";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
interface Props {
  addEmployee?: boolean;
}

const Employees: React.FC<Props> = ({ addEmployee }) => {
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [downloadCsvPermission, setDownloadCsvPermission] = useState(false);
  const [bulkUploadpermission, setBulkUploadPermission] = useState(false);
  const [AddPermission, setAddPermission] = useState(false);
  const [statusChangePermission, setStatusChangePermission] = useState(false);

  const [searchBarFilter, setsearchBarFilter] = useState("");
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  // new variables
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  let selected_data: Array<{ id: number }> = [];
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState<any>([]);
  const [csvBody, setCsvBody] = useState<any[]>([]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const navigate = useNavigate();
  const [employementStatusData, setEmployementStatusData] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);

  const { formatCurrency } = useFormatCurrency();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElCSV, setAnchorElCSV] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickCsv = (event: any) => {
    setAnchorElCSV(event.currentTarget);
  };
  const handleCloseCsv = () => {
    setAnchorElCSV(null);
  };
  const handleClose = (value: string) => {
    navigate({
      pathname: EMPLOYER_ALL_ROUTES.BULK_UPLOAD_PAGE,
      search: createSearchParams({
        calledFrom: EMPLOYER_ALL_ROUTES.EMPLOYEE,
        subpage: value,
      }).toString(),
    });
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    table_data(totalRecords, 0, "csv");
    handleCloseCsv();
  };

  const handleDownloadSelected = () => {
    table_data(tableLimit ?? 25, tableSkip ?? 0, "csv");
    handleCloseCsv();
  };
  let timeoutIdOne: any;
  useEffect(() => {
    setShowLoader(true);
    if (timeoutIdOne) {
      clearTimeout(timeoutIdOne);
    }

    const delayedApiCall = () => {
      // header_tag();
      // table_data();
      // get_status();
    };

    timeoutIdOne = setTimeout(delayedApiCall, 2000);

    return () => {
      if (timeoutIdOne) {
        clearTimeout(timeoutIdOne);
      }
    };
  }, []);
  useEffect(() => {
    setAddPermission(
      EMPLOYER?.Employees.add === undefined ? false : EMPLOYER.Employees.add
    );
    setDownloadCsvPermission(
      EMPLOYER?.Employees?.csv === undefined ? false : EMPLOYER?.Employees?.csv
    );
    setBulkUploadPermission(
      EMPLOYER?.Employees?.upload === undefined
        ? false
        : EMPLOYER?.Employees?.upload
    );
    setStatusChangePermission(
      EMPLOYER?.Employees?.status === undefined
        ? false
        : EMPLOYER?.Employees?.status
    );
  }, []);

  let timeoutIdTwo: any;
  useEffect(() => {
    if (timeoutIdTwo) {
      clearTimeout(timeoutIdTwo);
    }

    const delayedApiCall = () => {
      table_data();
      // get_status();
    };

    timeoutIdTwo = setTimeout(delayedApiCall, 2000);

    return () => {
      if (timeoutIdTwo) {
        clearTimeout(timeoutIdTwo);
      }
    };
  }, []);
  let timeoutIdThree: any;
  const [stop, setStop] = useState(true);
  useEffect(() => {
    if (timeoutIdThree) {
      clearTimeout(timeoutIdThree);
    }

    const delayedApiCall = () => {
      stop && get_status();
    };

    timeoutIdThree = setTimeout(delayedApiCall, 900);
    setStop(false);
    return () => {
      if (timeoutIdThree) {
        clearTimeout(timeoutIdThree);
      }
    };
  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      header_tag(statusData);
      get_status_employement(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const get_status_employement = (memberstatus: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      header_tag(memberstatus, statusData);
      setEmployementStatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const update_status = (value: number, id: string, type: string) => {
    const onSuccess = (res: any) => {
      table_data();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id,
      type,
      usertypeinfo.toLowerCase()
    );
  };

  // header list dynmaic

  const header_tag = (statusData?: any, employementStatus?: any) => {
    const onSuccess = (res: any) => {
      res.data.data.push(
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.createdAt, user_type_dateFormat, tz)
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.updatedAt, user_type_dateFormat, tz)
          },
        }
      );
      let data_change_field: any = [];
      res.data.data.forEach((e: any, index: number) => {
        if (index === 5) {
          data_change_field.push(
            {
              field: "status",
              headerName: "Member Status",
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row, index } = ValueFormatterParams;
                return (
                  <div id={`_${row.status.value}`}>
                    <div
                      className="status_dropdown"
                      id={`_${row.status.value}`}
                    >
                      {row.status.value === 32 ? (
                        <Active id={`_${row.status.value.toString()}`} />
                      ) : row.status.value === 33 ? (
                        <Inactive id={`_${row.status.value.toString()}`} />
                      ) : row.status.value === 34 ? (
                        <Void id={`_${row.status.value.toString()}`} />
                      ) : null}
                      <SelectDropdown
                        class_name="inputFieldd"
                        title=""
                        value={row.status.value}
                        attrName={["updatestatus", row, index]}
                        value_update={updateMasterState}
                        dropdown_data={statusData}
                        warn_status={false}
                        disabled={!statusChangePermission}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              field: "employement_status",
              headerName: "Employment Status",
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row, index } = ValueFormatterParams;
                row.employement_status.value =
                  row["Member Type"] === "Employee"
                    ? row.employement_status.value
                    : 39;
                return (
                  <div
                    id={`_${row.employement_status.value}`}
                    style={{ borderRadius: "20px" }}
                  >
                    <div
                      className="status_dropdown"
                      id={`_${row.employement_status.value?.toString()}`}
                    >
                      {row["Member Type"] !== "Employee" ? (
                        <NA
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : row.employement_status.value === 35 ? (
                        <Resigned
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : row.employement_status.value === 36 ? (
                        <Terminated
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : row.employement_status.value === 37 ? (
                        <Employed
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : row.employement_status.value === 38 ? (
                        <Probation
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : row.employement_status.value === 39 ? (
                        <NA
                          id={`_${row.employement_status.value?.toString()}`}
                        />
                      ) : null}
                      {row["Member Type"] === "Employee" ? (
                        <SelectDropdown
                          class_name="inputFieldd"
                          title=""
                          value={row.employement_status.value}
                          attrName={["updatestatusEmployment", row, index]}
                          value_update={updateMasterState}
                          dropdown_data={employementStatus}
                          warn_status={false}
                          disabled={!statusChangePermission}
                        />
                      ) : (
                        <span>NA</span>
                      )}
                    </div>
                  </div>
                );
              },
            }
          );
        }

        if (e.field === "Email") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  href={`mailto:${row.email}`}
                >
                  {row["Email"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Gender") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return capitalize(row.Gender);
            },
          });
        } else if (e.field === "First Name") {
          data_change_field.push({
            ...e,

            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `${EMPLOYER_ALL_ROUTES.EMPLOYEE_DETAILS_VIEW}?id=${row.id}`
                    );
                  }}
                >
                  {row["First Name"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Official Email") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  href={`mailto:${row["Official Email"]}`}
                >
                  {row["Official Email"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Date Of Joining") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Date Of Joining"] === "--" ||
                    row["Date Of Joining"] === null ||
                    row["Date Of Joining"] === ""
                    ? "--"
                    : date_time_format(
                      row["Date Of Joining"],
                      user_type_dateFormat,
                      tz
                    )}
                </span>
              );
            },
          });
        } else if (e.field === "Id") {
          data_change_field.push({
            ...e,
            headerName: "Member Id",

            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return <span>{row.employeeId}</span>;
            },
          });
        } else if (e.field === "Select Client") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return <span>{row["Select Client"]}</span>;
            },
          });
        } else if (e.field === "Date Of Birth") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {date_time_format(
                    row["Date Of Birth"],
                    user_type_dateFormat,
                    tz
                  )}
                </span>
              );
            },
          });
        } else if (e.field === "Salary") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Salary"] === "--" ||
                    row["Salary"] === null ||
                    row["Salary"] === "" ||
                    row["Salary"] === undefined
                    ? "--"
                    : formatCurrency(row["Salary"])}
                </span>
              );
            },
          });
        } else if (e.field === "Last Day of Working") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Last Day of Working"]
                    ? row["Last Day of Working"] === "--" ||
                      row["Last Day of Working"] === null ||
                      row["Last Day of Working"] === ""
                      ? "--"
                      : date_time_format(
                        row["Last Day of Working"],
                        `${user_type_dateFormat} hh:mm A`,
                        tz
                      )
                    : "--"}
                </span>
              );
            },
          });
        } else if (e.field === "employement_status") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {date_time_format(
                    row["Date Of Birth"],
                    user_type_dateFormat,
                    tz
                  )}
                </span>
              );
            },
          });
        } else if (e.field === "Select Client") {
          data_change_field.push({
            ...e,
            headerName: "Organisation ID/Name",
          });
        } else if (e.field === "Location") {
          data_change_field.push({
            ...e,
            headerName: "Organisation Location",
          });
        } else {
          data_change_field.push(e);
        }
      });
      setColumns(data_change_field);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYER_EMPLOYEE_SERVICES.get_header_tag(onSuccess, onError);
  };

  //Table Data
  const table_data = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setCsvBody(res.data.data.data);
      let data = res.data.data.data;
      data = data?.map((item: any) => {
        for (let key in item) {
          if (!item[key]) {
            item[key] = "--";
          }
        }
        return item;
      });
      setRows(data);

      setTotalRecords(res.data.data.countData);
      if (csv) {
        downloadCsv(data);
      }
    };
    const onError = (err: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };
    const currentRoute = E_BE_FILTER_CODE.EMPLOYEE.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    EMPLOYER_EMPLOYEE_SERVICES.get_employee_list(
      onSuccess,
      onError,
      limit ? limit : existingEntryIndex?.pageNumber ?? 25,
      skip ? skip : 0,
      startEndDate.startDate ? startEndDate.startDate : "",
      startEndDate.endDate ? startEndDate.endDate : "",
      searchBarFilter ? searchBarFilter : ""
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
    } else if (attrName[0] === "updatestatus") {
      if (attrName[1].status === 34) {
        return toast.warn("Void status is not Reversible");
      }
      update_status(value, attrName[1].id, "MEMBER");
    } else if (attrName[0] === "updatestatusEmployment") {
      if (value === 36 || value === 35) {
        if (
          !attrName[1]["Last Day of Working"] ||
          attrName[1]["Last Day of Working"] === "--"
        ) {
          return toast.warn(
            "Please Add Last Date of Working before to change status to Resigned/Terminated"
          );
        } else {
          return update_status(value, attrName[1].id, "EMPLOYMENT");
        }
      } else if (
        attrName[1].employement_status === 36 ||
        attrName[1].employement_status === 35
      ) {
        if (
          (attrName[1].employement_status === 36 && value === 35) ||
          (attrName[1].employement_status === 35 && value === 36)
        ) {
          return update_status(value, attrName[1].id, "EMPLOYMENT");
        } else {
          return toast.warning(
            "You can't Change status after Resigned/Terminated"
          );
        }
      } else if (attrName[1].employement_status === 38 && value === 37) {
        return update_status(value, attrName[1].id, "EMPLOYMENT");
      } else if (attrName[1].employement_status === 37 && value === 38) {
        return toast.warning("You can't change status Employed to Probation");
      } else if (value === 39) {
        return toast.warning("This Staus is not Applicable for Employee");
      } else {
        return update_status(value, attrName[1].id, "EMPLOYMENT");
      }
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else {
      attrName(value);
    }
    setArray_Delete(value);
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      table_data();
    };

    timeoutId = setTimeout(delayedApiCall, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  useEffect(() => {
    table_data();
  }, [startEndDate]);

  const downloadCsv = (DataCsv: any) => {
    const onSuccess = (res: any) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "members.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = DataCsv?.map((item: any) => {
      const employeeStatusObj: any =
        employementStatusData &&
        employementStatusData?.find(
          (status: any) => status.label === item.employement_status.value
        );
      return {
        ...item,
        Title: item?.Title,
        "Member Id": item?.EmployeeId,
        Id: item?.Id,
        "First Name": item["First Name"],
        "Last Name": item["Last Name"],
        Gender: item?.Gender,
        "Date Of Birth":
          item["Date Of Birth"] !== "--"
            ? date_time_format(item["Date Of Birth"], user_type_dateFormat, tz)
            : item["Date Of Birth"],
        "Member Status": item?.status?.label,
        "Employment Status": employeeStatusObj?.value,
        "Contact Number": item["Contact Number"],
        Email: item.Email,
        Department: item.Department,
        Designation: item.Designation,
        "Date Of Joining":
          item["Date Of Joining"] !== "--"
            ? date_time_format(
              item["Date Of Joining"],
              user_type_dateFormat,
              tz
            )
            : item["Date Of Joining"],
        "Last Day of Working":
          item["Last Day of Working"] && item["Last Day of Working"] !== "--"
            ? date_time_format(
              item["Last Day of Working"],
              user_type_dateFormat,
              tz
            )
            : "--",
        "Official Email": item["Official Email"],
        "Office Contact Number": item["Office Contact Number"],
        "Salary Bracket":
          item["Salary Bracket"] !== "--" &&
            item["Salary Bracket"] !== undefined
            ? convertSingleBracketToCurrencyFormat(
              item["Salary Bracket"],
              getCurrencyCodeRegex(userState.localInformation.currency),
              userState?.localInformation?.countryCurrencyCode,
              true
            ).replace(/₹/g, "Rs")
            : item["Salary Bracket"],
        Salary:
          item?.Salary !== "--" && item?.Salary !== undefined
            ? formatCurrency(item["Salary"])?.replace(/₹/g, "Rs")
            : item?.Salary,
        "Member Type": item["Member Type"],
        "Organization Employee ID": item["Organization Employee ID"],
        "Organisation Location": item.Location,
        "Relationship With Employee": item["Relationship With Employee"],
        "Primary Employee": item["Primary Employee"],
        Client: item.Client,
        "Created On": date_time_format(
          item?.createdAt,
          user_type_dateFormat,
          tz
        ),
        "Updated On": date_time_format(
          item?.updatedAt,
          user_type_dateFormat,
          tz
        ),
      };
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };
    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      usertypeinfo.toLowerCase(),
      "member"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}

      <Grid container spacing={2}>
        <Grid
          xs={4}
          style={{ display: "flex", alignItems: "center", gap: "16px" }}
        >
          <h3 className="mb-0 pr-3">Members</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid xs={8} className="text-right">
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {csvBody?.length > 0 && (
            <>
              {downloadCsvPermission && (
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClickCsv}
                  className="blueBtn download"
                  style={{ paddingLeft: 30, textTransform: "unset" }}
                >
                  Download CSV
                </Button>
              )}

              <Menu
                id="simple-menu"
                anchorEl={anchorElCSV}
                keepMounted
                open={Boolean(anchorElCSV)}
                onClose={handleCloseCsv}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          {/* <Link sx={{ height: "15px" }}
            title="Bulk Upload"
            underline="none"
            className="blueBtn upload"
            onClick={() => {
              navigate({
                pathname: EMPLOYER_ALL_ROUTES.BULK_UPLOAD_PAGE,
                search: createSearchParams({
                  calledFrom: EMPLOYER_ALL_ROUTES.EMPLOYEE,
                  portal: "employer",
                }).toString(),
              });
            }}
          >Bulk Upload</Link> */}
          {bulkUploadpermission && (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="blueBtn upload"
                sx={{
                  textTransform: "Capitalize",
                  padding: "4px 0px 4px 28px",
                  "&:hover": {
                    textDecoration: "none !important",
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                Bulk Upload
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setAnchorEl(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleClose("Employee")}>
                  Employee
                </MenuItem>
                <MenuItem onClick={() => handleClose("Dependent")}>
                  Dependent
                </MenuItem>
              </Menu>
            </>
          )}

          {AddPermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(EMPLOYER_ALL_ROUTES.ADD_EMPLOYEE);
              }}
            >
              Add
            </Link>
          )}
        </Grid>
        <Grid xs={12}>
          <UGTable
            header_data={columns}
            data={rows?.length > 0 ? rows : []}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.EMPLOYEE}
            refetch_data={table_data}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Employees;
