import CloseIcon from "@mui/icons-material/Close";
import { Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { groupHealthExculsionAddDTO } from "../../../../../Services/DTO/ProductConfigurator/GroupHealthDTO";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import { toast } from "react-toastify";
import { GROUP_HEALTH_SERVICES } from "../../../../../Services/ProductConfigurator/GroupHealthServices";
import { getProductCode } from "../../../../../Supporting files/HelpingFunction";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  api_url: string;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
  allExclusions?: any;
}

const AddExclusions: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  api_url,
  shortcutDisable,
  setShortcutDisable,
  allExclusions,
}) => {
  const [editExculsionStep, setEditExculsionStep] = useState<
    groupHealthExculsionAddDTO[]
  >([
    {
      exclusion: {
        title: "",
        description: "",
        warnDescription: false,
        warnTitle: false,
      },
    },
  ]);
  const updateMasterStateArray = (attrName: any, value: any) => {
    const array_data: any = [...editExculsionStep];
    if (attrName[1] === "title") {
      array_data[attrName[0]].exclusion.title = value;
      array_data[attrName[0]].exclusion.warnTitle = false;
    } else if (attrName[1] === "description") {
      array_data[attrName[0]].exclusion.description = value;
      array_data[attrName[0]].exclusion.warnDescription = false;
    }
    setEditExculsionStep(array_data);
  };
  // add catageory
  const addCategory = () => {
    let array_data: any = [...editExculsionStep];
    let hasWarning = false;
    array_data.forEach((item: any) => {
      const exclusion = item.exclusion;
      const { title, description } = exclusion;

      // Update warnTitle
      exclusion.warnTitle =  exclusion.warnTitle ? true : title.length < 2 || title === "";

      // Update warnDescription
      exclusion.warnDescription = description.length < 2 || description === "";

      if (
        !hasWarning &&
        (title.length < 2 ||
          title === "" ||
          description.length < 2 ||
          description === "")
      ) {
        hasWarning = true;
      }
    });
    setEditExculsionStep(array_data);

    const onSuccess = (res: any) => {
      value_update(attrName, false);
      setEditExculsionStep([
        {
          exclusion: {
            title: "",
            description: "",
            warnDescription: false,
            warnTitle: false,
          },
        },
      ]);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      toast.error(err.response.data.errors[0].message)
    };

    const hasDuplicateTitles = (data: any[]): boolean => {
      const titles = new Set<string>(); // Use a Set to track unique titles
      for (const item of data) {
        const title = item.exclusion?.title?.trim(); // Safely access and trim the title
        if (title) {
          if (titles.has(title)) {
            return true; // Duplicate found
          }
          titles.add(title); // Add the title to the Set
        }
      }
      return false; // No duplicates found
    };
    const isDuplicateTitleFound = hasDuplicateTitles(array_data);

    if (isDuplicateTitleFound) {
      return toast.error("Duplicate title found. Please remove the duplicate title.")
    }
    const dataToSend = array_data.map((item: any) => ({
      exclusion: {
        title: item.exclusion.title,
        description: item.exclusion.description
      }


    }));
    {
      !hasWarning &&
        GROUP_SUPER_TOPUP.createExcuslion(
          api_url,
          dataToSend,
          onSuccess,
          {},
          onError
        );
    }
  };

  const removePopup = () => {
    value_update(attrName, false);
    setEditExculsionStep([
      {
        exclusion: { title: "", description: "" },
      },
    ]);
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
  };
  const fullUrl = window.location.href; // Get the full URL

  const checkTittleDuplicacy = (index: number) => {
    let dto = {
      title: editExculsionStep[index].exclusion.title.trim(),
      module: "EXCLUSION",
      code:0,
    }
    const onSuccess = (res: any) => {
      let data = [...editExculsionStep];
      data[index] = {
        exclusion: {
          ...data[index].exclusion,
          warnTitle: res.data.data.error,
        },
      };
      setEditExculsionStep(data);
    };
    const onError = (err: any) => {
    };
    GROUP_HEALTH_SERVICES.checkTittleDuplicacy(dto, getProductCode(fullUrl), onSuccess, onError);
  };
  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
    // backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center" className="mt-0">
          <Grid xs={8}>
            <h4 className="mb-3">Add New Exclusion</h4>
            <p>You can add multiple exclusions at a time.</p>
          </Grid>
          <Grid xs={4} className="pt-3 text-right">
            <CloseIcon onClick={removePopup} />
          </Grid>
          <Grid xs={12} className="text-right">
            <Link
              className="greenBtn addfile ml-0"
              underline="none"
              onClick={() => {
                setEditExculsionStep((editExculsionStep) => [
                  ...editExculsionStep,
                  {
                    exclusion: {
                      title: "",
                      description: "",
                      warnDescription: false,
                      warnTitle: false,
                    },
                  },
                ]);
              }}
            >
              Add More
            </Link>
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          {editExculsionStep.map((data, index) => (
            <Grid container spacing={3}>
              <Grid xs={9}>
                <p className="labelTitle">{index + 1}. Exclusion</p>
              </Grid>
              <Grid xs={3} className="text-right">
                {index === 0 ? null : (
                  <Link
                    className="redBtn delete ml-0"
                    underline="none"
                    onClick={() => {
                      setEditExculsionStep((editExculsionStep) =>
                        editExculsionStep.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    Remove
                  </Link>
                )}
              </Grid>
              <Grid xs={8}>
                <RKTextField
                  class_name="inputField"
                  title={"Exclusion Name"}
                  value={data.exclusion.title}
                  attrName={[index, "title"]}
                  value_update={updateMasterStateArray}
                  warn_status={data.exclusion.warnTitle}
                  error_messg={
                    data.exclusion.title.length === 0
                      ? "Enter Name"
                      : data.exclusion.title.length < 2
                        ? "Min. 2 characters"
                        : "Exclusion already exist"
                  }
                  onKeyUp={()=>{
                    checkTittleDuplicacy(index)
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <TextAreaField
                  class_name="inputField multiline"
                  title={"Brief Description"}
                  value={data.exclusion.description}
                  attrName={[index, "description"]}
                  value_update={updateMasterStateArray}
                  warn_status={data.exclusion.warnDescription}
                  alphanumeric={true}
                  specialcharacter={true}
                  error_messg={
                    data.exclusion.description.length === 0
                      ? "Enter Description"
                      : data.exclusion.description.length < 2
                        ? "Min. 2 characters"
                        : ""
                  }
                />
              </Grid>
            </Grid>
          ))}
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={addCategory}
              disabled={editExculsionStep.some((step) => Boolean(step.exclusion.warnTitle))}

            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default AddExclusions;
