//used
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { GetAPI } from "../../../../../Services/API";
import { contactsFormDTO } from "../../../../../Services/DTO/ContactsDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { Delete, Get, Patch } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextFieldDisable from "../../../../../Supporting files/CurrencyDisable/CurrencyFieldDisable";
import DatePickerDisable from "../../../../../Supporting files/DatePickerDisable/DatePickerDisable";
import {
  date_time_format,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
  validatePincode,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdownDisable from "../../../../../Supporting files/MultipleSearchDropdownDisable/MultipleSearchDropdownDisable";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdownDisable from "../../../../../Supporting files/SelectDropdownDisable/SelectDropdownDisable";
import TextAreaFieldDisable from "../../../../../Supporting files/TextAreaFieldDisable/TextAreaFieldDisable";
import "../../../../../formLayout.scss";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function ContactDetail() {
  const navigate = useNavigate();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedcountry] = useState("");
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientName, setClientName] = useState("");
  const [openEmail, setOpenEmail] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [subSection, setSubSection] = useState("businessc");
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: any;
  }>({
    key: "",
    value: "",
  });

  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [formData, setFormData] = useState<contactsFormDTO[]>([]);
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [firstNamechar, setFirstNameChar] = useState<string>("");
  const [clientIdData, setClientIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientlocationData, setClientLocationData] = useState<any>([]);
  const [contactId, setContactId] = useState("");
  const [id, setId] = useState("");
  const [updateTimeStamp, setTimeStamp] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<any>("Personal Details");
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const OrganisationDetail = useRef(null);
  const [clientId, setClientId] = useState<{
    id: string;
    label: string;
  }>({
    id: "abc",
    label: "abc",
  });
  console.log("clientId",clientId)
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [showLoader, setShowLoader] = useState(true);
  const contact_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Contact
  );
  const [contactsEiditPermission, setContactsEiditPermission] = useState(false);
  let timeout: any;

  useEffect(() => {
    setShowLoader(true);
    setContactsEiditPermission(
      contact_permission?.edit === undefined ? false : contact_permission.edit
    );

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_client_list();
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
let checkCountry:any
  useEffect(() => {
    if (selectedCountry && checkCountry !==selectedCountry) {
      checkCountry = selectedCountry
      get_state_list(selectedCountry, (cb: any) => {
        setStateData(cb);
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
        setCityData(cb);
      });
    }
  }, [ selectedState]);



  let timeoutApi: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");

    if (timeoutApi) {
      clearTimeout(timeoutApi);
    }

    const delayedApiCall = () => {
      detail_view_api(id ?? "");
    };

    timeoutApi = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutApi) {
        clearTimeout(timeoutApi);
      }
    };
  }, [postQueryForm, updateTimeStamp]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    if (viewTabsStatus === "notes") {
      getNotes(id || "");
    }
  }, [viewTabsStatus]);

  const getNotes = (id: string) => {
    Get(`${URL_CONSTANTS.get_contact_notes}${id}`)?.then((res: any) => {
      let response = res.data.data.notes;
      setNotesData(response);
    });
  };

  const updatenotes = (value: any) => {
    let params = {
      notes: {
        description: value,
      },
    };
    Patch(`${URL_CONSTANTS.contact_notes}${id}`, params).then((data: any) => {
      let status = data[0];
      let response = data[1];
      if (status === "success") {
        toast.success("Data added successfully");
        // navigate("/homebroker?page=contacts#");
      } else {
        let param = status.response.data.errors[0].param;
        let error = status.response.data.errors[0].message;
        toast.error(param + " " + " " + error);
      }
    });
  };
  

  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      let response = res.data.data;
      response.unshift({
        label: "None of these",
        id: "",
      });
      setClientIdData(response);
    };
    const onError = (err: any) => {
      setShowLoader(false);
    };
    EMPLOYEE_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  const detail_view_api = (id: string) => {
    Get(`${URL_CONSTANTS.detail_contact}${id}`)?.then((res: any) => {
      console.log("res",res)
      const data = res.data.data[0];
      let response = data.layout;
      setUpdatedAt(data.updatedAt);
      setCreatedAt(data.createdAt);
      setClientId({
        id: data.organisationId.id,
        label: data.organisationId.label,
      });
      setClientName(data.organisationName);
      setContactId(data.contactId);
      let newData = response.map((dataMainsection: contactsFormDTO) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection.children.map(
          (dataSubsection) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection.children.map((dataRow) => {
              let newRow = { ...dataRow };
              newRow.children = dataRow.children.map((dataColumn) => {
                let newColumn = { ...dataColumn };
                newColumn.children = dataColumn.children.map((dataInput) => {
                  if (dataInput.field_lable === "First Name") {
                    setFirstNameChar(dataInput.value);
                  }
                  if (dataInput.field_lable === "Country") {
                    setSelectedcountry(dataInput.value);
                  }
                  if (dataInput.field_lable === "State") {
                    setSelectedState(dataInput.value);
                  }
                  if (dataInput.field_type === "contactId") {
                    return {
                      ...dataInput,
                      field_lable: "hide",
                      field_type: "hide",
                    };
                  } else {
                    return { ...dataInput, error: false };
                  }
                });
                return newColumn;
              });
              return newRow;
            });
            return newSubsection;
          }
        );
        return newMainSection;
      });
      setFormData(newData);
      getclientId(
        data.organisationId.id && data.organisationId.id !== "None of these"
          ? data.organisationId.id
          : "643fe50d830764001ebde5ea"
      );
    });
  };
  // get client detail
  const getclientId = (id: string) => {
    Get(`${URL_CONSTANTS.get_client_location}${id}`)?.then((res: any) => {
      setClientLocationData(res.data.data[0]);
    });
  };
  // delete contact
  const delete_view = () => {
    Delete(`${URL_CONSTANTS.delete_contact}${id}`)
      .then((res: any) => {
        if (res) {
          setRemoveStatus(false);
          navigate(ALL_ROUTES.CONTACTS);
        }
      })
      .catch((error) => {
        // alert(error);
        return [error, {}];
      });
  };

  const updateMasterState = (attrName: any, value: any) => {
    setIndividualEditKey({
      key:
        "layout." +
        attrName[0] +
        ".children." +
        attrName[1] +
        ".children." +
        attrName[2] +
        ".children." +
        attrName[3] +
        ".children." +
        attrName[4] +
        ".value",

      value: value,
    });
    if (attrName === "notes_new_data") {
      setNotesData((notesData) => [
        ...notesData,
        { name: userState.username, date: new Date(), description: value },
      ]);
      updatenotes(value);
    } else if (
      attrName === setViewTabsStatus ||
      attrName === setPostQueryForm
    ) {
      attrName(value);
    } else if (attrName === setClientName) {
      setClientName(value);
      setIndividualEditKey({
        key: "organisationName",
        value: value,
      });
    } else if (attrName === setClientId) {
      setClientId(value);
      setClientName("");

      const onSuccess = (res: any) => {
        setClientLocationData(res.data.data[0]);
      };

      GetAPI.call(
        `${URL_CONSTANTS.get_client_location}${value.id}`,
        onSuccess,
        {},
        () => {}
      );
      if (value.id === "None of these") {
        return;
      }
      //this bug is resolved by Mr Shahid Yousuf
      //the error was value must be string 
      Patch(`${URL_CONSTANTS.individual_edit}${id}`, {
        key: "organisationId",
        value: value,
      }).then((data: any) => {
        let status = data[0];
        let response = data[1];
        if (status === "success") {
          toast.success("Data update successfully");
        }
        if (status.response?.data.status === "failed") {
          let param = status.response.data.errors[0].param;
          let error = status.response.data.errors[0].message;
          toast.error(param + " " + " " + error);
        }
      });
    } else {
      let data: contactsFormDTO[] = formData;

      //For required fields
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value =
          value.label === undefined ? value : value.label;
        setSelectedcountry(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value =
          value.label === undefined ? value : value.label;
        setSelectedState(value);

        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value =
          value.label === undefined ? value : value.label;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setFormData(data);
      // attrName(value);
    }
  };

  const individual_edit = (attrName?: any) => {
    let data: contactsFormDTO[] = formData;
    //For required fields
    if (individualEditKey?.key !== "organisationId") {
      if (
        data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
          ?.children[attrName[3]]?.children[attrName[4]]?.field_type ===
          "country" ||
        data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
          ?.children[attrName[3]]?.children[attrName[4]]?.field_type ===
          "state" ||
        data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
          ?.children[attrName[3]]?.children[attrName[4]]?.field_type === "city"
      ) {
        const index_country =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
          "country"
            ? attrName[3]
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "state"
            ? attrName[3] - 1
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "city"
            ? attrName[3] - 2
            : 0;
        const index_state =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
          "country"
            ? attrName[3] + 1
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "state"
            ? attrName[3]
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "city"
            ? attrName[3] - 1
            : 0;
        const index_city =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
          "country"
            ? attrName[3] + 2
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "state"
            ? attrName[3] + 1
            : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].field_type ===
              "city"
            ? attrName[3]
            : 0;

        const country =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[index_country].children[attrName[4]].value;
        const state =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[index_state].children[attrName[4]].value;
        const city =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[index_city].children[attrName[4]].value;

        if (
          country.toString().length === 0 ||
          state.toString().length === 0 ||
          city.toString().length === 0
        ) {
          toast.error("Please check Country, state or city may be empty");
        } else {
          updateAddressDetails(
            "layout." +
              attrName[0] +
              ".children." +
              attrName[1] +
              ".children." +
              attrName[2] +
              ".children." +
              index_country +
              ".children." +
              attrName[4] +
              ".value",
            country
          );
          updateAddressDetails(
            "layout." +
              attrName[0] +
              ".children." +
              attrName[1] +
              ".children." +
              attrName[2] +
              ".children." +
              index_state +
              ".children." +
              attrName[4] +
              ".value",
            state
          );
          updateAddressDetails(
            "layout." +
              attrName[0] +
              ".children." +
              attrName[1] +
              ".children." +
              attrName[2] +
              ".children." +
              index_city +
              ".children." +
              attrName[4] +
              ".value",
            city,
            "city"
          );
          setDisableAddress(false);
        }
      } else {
        if (individualEditKey.value === "" && individualEditKey.key != "") {
          return toast.error("Field Can't be Empty");
        } else if (
          data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
            ?.children[attrName[3]]?.children[attrName[4]]?.field_type ===
          "email"
        ) {
          let checkError = validateEmail(individualEditKey.value);
          if (!checkError) {
            return toast.error("Entered Email is not valid");
          }
        } else if (
          data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
            ?.children[attrName[3]]?.children[attrName[4]]?.field_lable ===
          "Pincode"
        ) {
          let checkError = validatePincode(individualEditKey.value);
          if (!checkError) {
            return toast.error("Entered PinCode is not valid");
          }
        } else if (
          data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
            ?.children[attrName[3]]?.children[attrName[4]]?.field_type ===
          "phone"
        ) {
          let checkError = validateMobileNumber(individualEditKey.value);
          if (!checkError) {
            let label =
              data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
                ?.children[attrName[3]]?.children[attrName[4]]?.field_lable;
            return toast.error(`Entered ${label} is not valid`);
          }
        }
        setTimeStamp(true);
        Patch(
          `${URL_CONSTANTS.individual_edit}${id}`,
          individualEditKey ? individualEditKey : ""
        ).then((data: any) => {
          let status = data[0];
          let response = data[1];
          let dataItem: any = [...formData];

          if (status === "success") {
            toast.success("Data update successfully");
          }
          let flag  = true
          if (status.response.data.status === "failed" && flag) {
            let param = status.response.data.errors[0].param;
            let error = status.response.data.errors[0].message;
            toast.error(param + " " + " " + error);
            flag = false
          }
        });
      }
    } else {
      Patch(`${URL_CONSTANTS.individual_edit}${id}`, {
        key: "organisationName",
        value: clientName,
      }).then((data: any) => {
        let status = data[0];
        let response = data[1];
        if (status === "success") {
          toast.success("Data update successfully");
        }
        if (status.response.data.status === "failed") {
          let param = status.response.data.errors[0].param;
          let error = status.response.data.errors[0].message;
          toast.error(param + " " + " " + error);
        }
      });
    }
  };

  const updateAddressDetails = (key: string, value: string, city?: string) => {
    Patch(`${URL_CONSTANTS.individual_edit}${id}`, {
      key: key,
      value: value,
    }).then((data: any) => {
      let status = data[0];
      let response = data[1];
      let dataItem: any = [...formData];
      if (status === "success") {
        if (city === "city") {
          toast.success("Data update successfully");
        }
      }
      if (status?.response?.data?.status === "failed") {
        let param = status?.response?.data?.errors[0]?.param;
        let error = status?.response?.data?.errors[0]?.message;
        toast.error(param + " " + " " + error);
      }
    });
  };

  const handleClicks = (id: any) => {
    setSubSection(id);
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">{firstNamechar.charAt(0)}</span>
            <div>
              <h3>{firstNamechar}</h3>
              <p>
                C00{contactId} - Created on{" "}
                {date_time_format(createdAt, user_type_dateFormat, tz)}
              </p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {contactsEiditPermission && (
              <div>
                <Tooltip title="Global Edit">
                  <Link
                    className="actionLinks"
                    onClick={() => {
                      navigate(`${ALL_ROUTES.EDIT_CONTACT}?id=${id}`);
                    }}
                  >
                    <BorderColorIcon />
                  </Link>
                </Tooltip>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
          <Grid xs={12}>
            <>
              <Grid container spacing={3} className="p-0">
                <Grid xs className="leftSection h-100">
                  {viewTabsStatus === "overview" ? (
                    <>
                      {formData.map(
                        (dataMainSection: contactsFormDTO, index: number) => (
                          <>
                            <Link
                              className={
                                sectionStatus === dataMainSection.name
                                  ? `active sectionLink`
                                  : "sectionLink personalDetailsSection"
                              }
                              onClick={() => {
                                handleClicks(dataMainSection.name);
                                SetSectionStatus(
                                  dataMainSection.name === undefined
                                    ? ""
                                    : dataMainSection.name
                                );
                              }}
                            >
                              <span>{dataMainSection.name}</span>
                            </Link>
                            {dataMainSection.children.map((dataSubsection) => (
                              <ul className="accordion_list">
                                <li>
                                  <Link
                                    className={
                                      subsectionStatus === dataSubsection.name
                                        ? `inner_link active`
                                        : `inner_link`
                                    }
                                    onClick={() => {
                                      handleClicks(dataSubsection.name);
                                      SetsubSectionStatus(dataSubsection.name);
                                      SetSectionStatus(
                                        dataMainSection.name === undefined
                                          ? ""
                                          : dataMainSection.name
                                      );
                                    }}
                                  >
                                    {dataSubsection.name}
                                  </Link>
                                </li>
                              </ul>
                            ))}
                          </>
                        )
                      )}
                      <Link
                        className={
                          sectionStatus === "OrganisationDetail"
                            ? "active sectionLink OrganisationDetail"
                            : "sectionLink OrganisationDetail"
                        }
                        onClick={() => {
                          scrollToSection(OrganisationDetail);
                          SetSectionStatus("OrganisationDetail");
                          setShowLoader(true);
                        }}
                      >
                        <span>Organisation Details</span>
                      </Link>
                    </>
                  ) : viewTabsStatus === "notes" ? (
                    <Link className={`active sectionLink`}>
                      <span>Notes</span>
                    </Link>
                  ) : null}
                </Grid>
                <Grid xs className="rightSection h-100">
                  <>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      justifyContent="end"
                      marginBottom={"10px"}
                    >
                      <Grid lg={7} md={7} xs={12} className="pl-0">
                        <div className="productTabs ">
                          <Button
                            className={
                              viewTabsStatus === "overview"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(setViewTabsStatus, "overview");
                            }}
                          >
                            Overview
                          </Button>

                          <Button
                            className={
                              viewTabsStatus === "notes"
                                ? "defaultTab active"
                                : "defaultTab"
                            }
                            onClick={() => {
                              updateMasterState(setViewTabsStatus, "notes");
                            }}
                          >
                            Notes
                          </Button>
                        </div>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        xs={12}
                        style={{ textAlign: "right" }}
                        className="text-sm-center"
                      >
                        <p className="lastupdate">
                          Last Update:{" "}
                          {date_time_format(
                            updatedAt,
                            user_type_dateFormat,
                            tz
                          )}
                        </p>
                      </Grid>
                    </Grid>
                  </>
                  {viewTabsStatus === "overview" ? (
                    <>
                      {formData.map(
                        (
                          dataMainSection: contactsFormDTO,
                          indexMainSection: number
                        ) => (
                          // Main section
                          <Box
                            onMouseEnter={() => {
                              SetSectionStatus(dataMainSection.name);
                            }}
                            className="detailSection"
                          >
                            <div id={dataMainSection.name}>
                              <div className="sectionTitle">
                                <h4>{dataMainSection.name}</h4>
                              </div>
                              {dataMainSection.children.map(
                                (dataSubsection, indexSubsection) => (
                                  // Sub Section
                                  <Grid container rowSpacing={3}>
                                    <Grid xs={12}>
                                      <h5
                                        id={dataSubsection.name}
                                        key={dataSubsection.type}
                                        className="subHeading"
                                      >
                                        {dataSubsection.name}
                                      </h5>
                                      {dataSubsection.children.map(
                                        (dataRow, indexRow) => (
                                          // Row
                                          <Grid
                                            onMouseEnter={() => {
                                              SetsubSectionStatus(
                                                dataSubsection.name
                                              );
                                            }}
                                            container
                                            spacing={3}
                                          >
                                            {dataRow.children.map(
                                              (dataColumn, indexColumn) => (
                                                // Columns created
                                                <Grid xs>
                                                  {dataColumn.children.map(
                                                    (dataInput, indexInput) => (
                                                      // Input filed
                                                      <>
                                                        {dataInput.field_type ===
                                                        "singleselect" ? (
                                                          <SelectDropdownDisable
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            dropdown_data={
                                                              dataInput.pickliststep
                                                                ? dataInput.pickliststep
                                                                : []
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "phone" ? (
                                                          <RKTextFieldDisable
                                                            validation_type="numeric"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 11
                                                                : dataInput.characterLimit
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "email" ? (
                                                          <RKTextFieldDisable
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            validation_type="email"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : validateEmail(
                                                                    dataInput.value
                                                                  ) === false
                                                                ? `Enter a valid email Id`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "datepicker" ? (
                                                          <DatePickerDisable
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            error_message="Enter a Valid Date"
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            max_date={
                                                              new Date()
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "searchselect" ? (
                                                          <SearchDropdownDisable
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            data={
                                                              dataInput.pickliststep
                                                                ? dataInput.pickliststep
                                                                : []
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_messg={
                                                              "Select the value"
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "checkbox" ? (
                                                          <CheckBox
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            name={
                                                              dataInput.field_lable
                                                            }
                                                            id={dataInput._id}
                                                            value={Boolean(
                                                              dataInput.value
                                                            )}
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            default_check={
                                                              false
                                                            }
                                                            disable={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "country" ? (
                                                          <SearchDropdownDisable
                                                            disable_status={
                                                              disableAddress
                                                            }
                                                            set_disable_status={
                                                              setDisableAddress
                                                            }
                                                            class_name="inputField"
                                                            title="Country"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            params={
                                                              individual_edit
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={countryData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_messg="Please select country"
                                                            value_type_id_value={
                                                              true
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "state" ? (
                                                          <SearchDropdownDisable
                                                            disable_status={
                                                              disableAddress
                                                            }
                                                            set_disable_status={
                                                              setDisableAddress
                                                            }
                                                            class_name="inputField"
                                                            title="State"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={stateData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            error_messg="Please select state"
                                                            value_type_id_value={
                                                              true
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "city" ? (
                                                          <SearchDropdownDisable
                                                            disable_status={
                                                              disableAddress
                                                            }
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            set_disable_status={
                                                              setDisableAddress
                                                            }
                                                            class_name="inputField"
                                                            title="City"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={cityData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_messg="Please select city"
                                                            value_type_id_value={
                                                              true
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "number" ? (
                                                          <RKTextFieldDisable
                                                            id={id}
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            charcterlimit={dataInput.maxDigits }
                                                            params={
                                                              individual_edit
                                                            }
                                                            validation_type="numeric"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={true}
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ==
                                                          "currency" ? (
                                                          <CurrencyTextFieldDisable
                                                            validation_type="numeric"
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} Limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "multiselect" ? (
                                                          <MultipleSearchDropdownDisable
                                                            class_name="inputField"
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            dropdown_data={
                                                              dataInput.pickliststep
                                                                ? dataInput.pickliststep
                                                                : []
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            error_messg={
                                                              "Select the value"
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "multiline" ? (
                                                          <TextAreaFieldDisable
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            specialcharacter={
                                                              !dataInput.speacialChar
                                                                ? false
                                                                : dataInput.speacialChar
                                                            }
                                                            id={id}
                                                            params={
                                                              individual_edit
                                                            }
                                                            alphanumeric={
                                                              !dataInput.alphaNumeric
                                                                ? false
                                                                : dataInput.alphaNumeric
                                                            }
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 30
                                                                : dataInput.characterLimit
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "hide" ? null : (
                                                          <RKTextFieldDisable
                                                            disabled={
                                                              contactsEiditPermission ===
                                                              false
                                                            }
                                                            specialcharacter={
                                                              // !dataInput.speacialChar
                                                              //   ? false
                                                              //   : dataInput.speacialChar
                                                              true
                                                            }
                                                            alphanumeric={
                                                              // !dataInput.alphaNumeric
                                                              //   ? false
                                                              //   : dataInput.alphaNumeric
                                                              true
                                                            }
                                                            
                                                            validation_type="name"
                                                            params={
                                                              individual_edit
                                                            }
                                                            id={id}
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </Grid>
                                              )
                                            )}
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </div>
                          </Box>
                        )
                      )}
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus("OrganisationDetail");
                        }}
                        className="detailSection"
                        ref={OrganisationDetail}
                      >
                        <div>
                          <div className="sectionTitle">
                            <h4>Organisation Detail</h4>
                          </div>
                          <Grid container spacing={3} className="mx-0">
                            <Grid xs={6}>
                              <SearchDropdown
                                class_name="inputField"
                                title="Select Organisations"
                                value={clientId?.label}
                                attrName={setClientId}
                                value_update={updateMasterState}
                                data={clientIdData}
                                warn_status={false}
                                error_message="Select state"
                                disabled={contactsEiditPermission === false}
                                // required={true}
                              />
                            </Grid>
                            {clientId?.label === "None of these" ? (
                              <Grid xs={6}>
                                <RKTextFieldDisable
                                  validation_type="name"
                                  class_name="inputField"
                                  title="Organisation Name"
                                  value={clientName}
                                  attrName={setClientName}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  error_messg="Select Field"
                                  params={individual_edit}
                                  disabled={contactsEiditPermission === false}
                                  // required={true}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                          {clientlocationData && (
                            <>
                              {clientId?.label === "None of these" ? null : (
                                <Grid container className="mx-0">
                                  <Grid xs={12} className="org-info mb-4">
                                    <div className="logo-div">
                                      <div className="existing_client_logo">
                                        <img
                                          src={clientlocationData?.logo}
                                          alt="digit"
                                          className="company_logo"
                                        />
                                      </div>
                                    </div>

                                    <div className="info-details">
                                      <Grid container>
                                        <Grid xs={12}>
                                          <div className="inner-info-div">
                                            <p className="company-name">
                                              {clientlocationData?.Organisation}
                                              <span>
                                                {clientlocationData?.Ownership}
                                              </span>
                                            </p>
                                          </div>
                                        </Grid>
                                        <Grid xs={12} sx={{ display: "flex" }}>
                                          <div className="inner-info-div">
                                            <p className="info_p">
                                              Website Url
                                              <span>
                                                <a
                                                  className="email_list"
                                                  href={`http://www.${
                                                    clientlocationData
                                                      ? clientlocationData[
                                                          "Website URL"
                                                        ]
                                                      : ""
                                                  }`}
                                                  target="_blank"
                                                >
                                                  {clientlocationData
                                                    ? clientlocationData[
                                                        "Website URL"
                                                      ]
                                                    : ""}
                                                </a>
                                              </span>
                                            </p>
                                          </div>

                                          <div className="inner-info-div">
                                            <p className="info_p">
                                              Contact Number
                                              <span>
                                                {clientlocationData
                                                  ? clientlocationData[
                                                      "Organization Phone Number"
                                                    ]
                                                  : ""}
                                              </span>
                                            </p>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>

                                  <Grid xs={4}></Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </div>
                      </Box>
                    </>
                  ) : null}
                  {viewTabsStatus === "notes" ? (
                    <>
                      <Box className="detailSection">
                        <div className="sectionTitle">
                          <h4>Notes</h4>
                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (contactsEiditPermission === false) {
                                  return toast.error("Permissions Not Allowed");
                                }
                                setPostQueryForm(true);
                              }}
                            >
                              Add Notes
                            </Link>
                          </div>
                        </div>
                        <NotesSection data={notesData} />
                      </Box>
                      <PostQueryForm
                        attrName={setPostQueryForm}
                        open_status={postQueryForm}
                        value_update={updateMasterState}
                        data={updatenotes}
                        type={"contact"}
                      />
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>

        {/* Footer Section */}

        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
          data={updatenotes}
        />
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={removeStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setRemoveStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are want to delete this Contact.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button
                  onClick={delete_view}
                  variant="contained"
                  className="submitBtn"
                >
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
export default ContactDetail;
