import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Optout from "@mui/icons-material/ExitToApp";
import {
  default as Enrolled,
  default as HowToRegIcon,
  default as Requested,
} from "@mui/icons-material/HowToReg";
import EnrollmentRequested from "@mui/icons-material/MarkChatRead";
import EnrolmentInitated from "@mui/icons-material/MobileFriendly";
import NA from "@mui/icons-material/NotAccessible";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../../APIVar/EmployeeUrlConstant";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import InitiateAClaim from "../../../../../Components/Broker/Claim/InitiateAClaimSp/InitiateAClaim";
import ViewDocuments from "../../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import AddNewRequest from "../../../../../Components/Broker/Endorsment/AddNewRequestSP/AddNewRequest";
import NetworkHospital from "../../../../../Components/Broker/NetworkHospital/NetworkHospital";
import AddMember from "../../../../../Components/Common/AddMember/AddMember";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../Services/Employee/EmployeePolicyService";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../Services/EmployerServices/Settings/SetingServices";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import {
  countOccurrencesInCoverageConfigString,
  date_time_format,
} from "../../../../../Supporting files/HelpingFunction";
import "../GroupHealthPolicy/GroupHealthPolicyEmpV2.scss";
import { toast } from "react-toastify";
import AddNominee from "../../../../../Components/Common/Nominee/NomineDetail/AddNomineeDetail";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";

function GroupHealthPolicyEmpV2() {
  const navigate = useNavigate();
  const { dateFormat, timeZone } = useAppSelector((state) => state.userDetailsSlice.localInformation);
  const { formatCurrency } = useFormatCurrency();
  const employeeId = useAppSelector((state) => state.userDetailsSlice.id);
  const { userType, Gender, "First Name": fname, "Last Name": lname,id:userId } = useAppSelector((state: any) => state.userDetailsSlice);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id") || "";
  const [policyData, setPolicyData] = useState<any>({});
  const [paidClaimAmount, setPaidClaimAmount] = useState(0);
  const [featureAndExclusion, setFeatureAndExclusion] = useState<Array<any>>([]);
  const [InitiateClaim, setInitiateClaim] = useState(false);
  const [addNewRequest, setAddNewRequest] = useState(false);
  const [addNewMember, setAddMember] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [clamForm, setclamForm] = useState(false);
  const [ecard, setEcard] = useState([]);
  const [claimFormData, setclaimFormData] = useState([]);
  const [tpaName, settpaName] = useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [employee, setEmployee] = useState<any>();
  const [prioritystatusEnrollmentData, setprioritystatusEnrollmentData] = useState<any>([]);
  const [prioritystatusDataNominee, setprioritystatusDataNominee] = useState<any>([]);
  const [basicConfig, setbasicConfig] = useState<string>("");
  const [parentConfig, setparentConfig] = useState<string>("");
  const [openNomine, setOpenNomine] = useState<boolean>(false);
  let enroledMembers: number = policyData?.members?.filter(
    (data1: any) => data1?.enrolment_status === 41
  )?.length;
  if (
    policyData?.status === 41 //&& selectedMemberType === "Employee"
  ) {
    enroledMembers += 1;
  }
  let enroledNominees: number =
    policyData?.nominee?.filter((data1: any) => data1?.enrolment_status === 44)
      ?.length || 0;


  let timeout: any;
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_status_enrollment();
      get_status_nominee();
      getPolicyDetails();

    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };


  }, []);
  const getPolicyDetails = () => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      setEmployee(data);
      setPolicyData(data?.policyDetails[0]);
      settpaName(data?.policyDetails[0].tpaName);
      setPaidClaimAmount(data?.policyDetails[0].totalClaimAmt);
      setPolicyNumber(data?.policyDetails[0].policyNumber);
      setFeatureAndExclusion(data?.featureAndExclusion);
      let basicConfig = data?.policyDetails[0]?.planType?.basicConfig ?? "";
      let parentConfig = data?.policyDetails[0]?.planType?.parentConfig ?? "";
      setbasicConfig(basicConfig);
      setparentConfig(parentConfig);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const url = `${EMPLOYEE_URL_CONSTANTS.get_policy_details}?id=${id}&tab=FEATURES`;
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  const parseCoverageString = (basicConfig: string, parentConfig: string) => {
    let EmployeeStr = "";
    let ParentStr = "";
    if (!basicConfig) {
      EmployeeStr = "Employee";
    } else {
      if (parentConfig === "Nill") {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "S"
          );
          const childrenOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "C"
          );
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"
              }`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee & ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"
              }`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        return { EmployeeStr };
      } else {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "S"
          );
          const childrenOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "C"
          );
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"
              }`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"
              }`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        if (parentConfig) {
          if (parentConfig?.includes("+")) {
            const inLawsOccurences = countOccurrencesInCoverageConfigString(
              parentConfig,
              "PIL"
            );
            const parentsOccurences = countOccurrencesInCoverageConfigString(
              parentConfig,
              "P"
            );
            if (parentsOccurences && inLawsOccurences) {
              ParentStr = `${parentsOccurences} Parents & ${inLawsOccurences} Parents-In-Law`;
            } else if (parentsOccurences) {
              ParentStr = `${parentsOccurences} Parents`;
            } else if (inLawsOccurences) {
              ParentStr = `${inLawsOccurences} Parents-In-Law`;
            }
          }
        }
      }
    }
    return { EmployeeStr, ParentStr };
  };

  const updateMasterState = (attrName: any, value: string) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else {
      attrName(value);
    }
  };

  const getEcards = (id: string) => {
    const onSuccess = (res: any) => {
      setEcard(res.data.data.ecard);
    };
    const onError = (err: any) => {
      console.log(err, "err");
    };
    let api = `${URL_CONSTANTS.get_e_cards(userType.toLowerCase())}${id}`;
    EMPLOYEE_POLICY_SERVICES.employeeEcards(api, onSuccess, onError);
  };


  const getClaimForms = (insurerId:string,policyId:string) => {
    const onSuccess = (res: any) => {
      console.log("cccccccc",res.data.data)
      setclaimFormData(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err, "err");
    };
    let api = URL_CONSTANTS.get_ClaimForm(userType.toLowerCase(),insurerId,policyId)
    EMPLOYEE_POLICY_SERVICES.employeeEcards(api, onSuccess, onError);
  };
  const get_status_enrollment = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusEnrollmentData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_enrolment(onSuccess, onError);
  };

  const get_status_nominee = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusDataNominee(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_nominee(onSuccess, onError);
  };

  // useEffect(() => {
  //   getPolicyDetails();
  // }, [prioritystatusEnrollmentData]);

  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [modalKey, setModalKey] = useState<"" | "features" | "exclusions">("features");

  const filteredfeatures = featureAndExclusion?.filter((item: any) =>
    item?.featureData?.some((feaData: any) =>
      feaData?.category?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
      feaData?.description?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
      feaData?.features?.some((item1: any) =>
        item1?.featureDetails?.name?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
        item1?.name?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
        item1?.description?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
        item1?.featureDetails?.description?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()))))

  const filteredExclusions = featureAndExclusion[0]?.exclusion?.filter((data: any) =>
    data?.name?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
    data?.title?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()) ||
    data?.description?.toLowerCase()?.includes(searchBarFilter?.toLowerCase()))

  return (
    <>
      <AddNewRequest
        attrName={setAddNewRequest}
        open_status={addNewRequest}
        value_update={updateMasterState}
      />
      <InitiateAClaim
        attrName={setInitiateClaim}
        open_status={InitiateClaim}
        value_update={updateMasterState}
        client_id_status={true}
      />
      <AddMember
        attrName={setAddMember}
        open_status={addNewMember}
        value_update={updateMasterState}
        apiUrl=""
        policyId={policyData?.policyId}
        policyBeingWorkedOn={policyData}
        memberId={employeeId}
        callApi={getPolicyDetails}
        policyMember={policyData?.members}
      />
      <ViewDocuments
        attrName={setViewDocuments}
        open_status={viewDocuments}
        value_update={updateMasterState}
        data={ecard}
      />
        <ViewDocuments
        attrName={setclamForm}
        open_status={clamForm}
        value_update={updateMasterState}
        data={claimFormData}
      />
      <AddNominee
        id={employeeId}
        open_status={openNomine}
        attrName={setOpenNomine}
        value_update={updateMasterState}
        apiUrl={""}
        policyIdEmployee={id}
        refetchData={getPolicyDetails}
        existingNominees={policyData?.nominee}
        policyBeingWorkedOn={policyData}
      />
      <Box className="grouppolicy_wrapper">
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={4} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Policy Detail</h3>
            </div>
          </Grid>
          <Grid
            xs={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={() => {
                setAddNewRequest(true);
              }}
            >
              Endorsement
            </Link>
            {policyData?.productType != "Group Term" && (
              <Link
                underline="none"
                className="blueBtn addfile"
                onClick={() => {
                  if (
                    enroledMembers >=
                    policyData?.policySettings?.maxMemberPerFamily
                  ) {
                    return toast.warning(
                      "Maximun number of family members Enroled limit exceeds"
                    );
                  }
                  if (policyData?.status === 42 || policyData?.status === 61) {
                    return toast.warning(
                      "Since the Employee has been Opt-Out from this policy, no new member may be added to this Policy."
                    );
                  }
                  setAddMember(true);
                }}
              >
                Add Member
              </Link>
            )}
            {policyData?.policySettings?.nomination && (
              <Link
                underline="none"
                className="blueBtn addfile"
                onClick={() => {
                  // if (!policyData.policySettings.nomination) {
                  //   return toast.error("Nomination is not allowed for this Policy")
                  // } else
                  if (
                    enroledNominees >= policyData?.policySettings?.maxNomination
                  ) {
                    return toast.error(
                      "Maximun number of nominees Enroled limit exceeds"
                    );
                  } else {
                    setOpenNomine(true);
                  }
                }}
              >
                Add Nominee
              </Link>
            )}
            {policyData?.policySettings?.healthCard && (
              <Link
                underline="none"
                className="blueBtn download"
                onClick={() => {
                  setViewDocuments(true);
                  getEcards(policyData?.policyId);
                }}
              >
                E- Card
              </Link>
            )}
              <Link
                underline="none"
                className="blueBtn download"
                onClick={() => {
                  setclamForm(true);
                  getClaimForms(policyData?.insurer._id,policyData?.policyId );
                }}
              >
                Claim Form
              </Link>
            <Link
              underline="none"
              className="blueBtn docs"
              onClick={() => {
                setInitiateClaim(true);
              }}
            >
              Claim Request
            </Link>
          </Grid>
        </Grid>
        <div className="main_wrapper">
          <Grid container spacing={3}>
            <Grid xs={12} md={12} lg={6} className="left_section">
              <h5 className="mb-2">
                Policy <span>#{policyData?.policyNumber}</span>
              </h5>
              <h5 className="mb-2">
                <span>
                  Start Date :{" "}
                  {date_time_format(policyData.startDate, dateFormat, timeZone)}
                </span>
                <span>
                  End Date:{" "}
                  {date_time_format(policyData.endDate, dateFormat, timeZone)}
                </span>
              </h5>
              <div className="coverage_section">
                <ul>
                  <li>
                    <img
                      src={
                        policyData?.insurer?.insurerBasicInfo?.logo
                          ? policyData?.insurer?.insurerBasicInfo?.logo
                          : "./images/insurerlogo/digit.svg"
                      }
                      alt=""
                      height={57}
                    />
                  </li>
                  <li>
                    Sum Insured{" "}
                    <span>{formatCurrency(policyData?.sumInsured)}</span>
                  </li>
                  <li>
                    Balance SI{" "}
                    <span>{formatCurrency(+policyData?.sumInsured - paidClaimAmount)}</span>
                  </li>
                  <li className="text-right">
                    Coverage for
                    <span>
                      {parseCoverageString(basicConfig, parentConfig)?.ParentStr
                        ? parseCoverageString(basicConfig, parentConfig)
                          ?.EmployeeStr +
                        ", " +
                        parseCoverageString(basicConfig, parentConfig)
                          ?.ParentStr
                        : parseCoverageString(basicConfig, parentConfig)
                          ?.EmployeeStr}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="policycover_section ">
                <h5 style={{ width: "100%", textAlign: "left" }}>
                  Family Defination:{" "}
                  <span style={{
                    color: "#000",
                    fontWeight: "300",
                    marginLeft: "5px",
                  }}
                  >
                    {policyData.planType?.familyDefinition}
                  </span>
                </h5>
              </div>
              <div className="policycover_section">
                <Grid container spacing={3}>
                  <Grid xs={12} className="pl-0 pr-0">
                    <>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <h5>Covered Members:</h5>
                        <h5>Relation</h5>
                        <h5>DOB</h5>
                        <h5>Payable</h5>
                        <h5>Enrollment Status</h5>
                      </Box>
                      <ul className="policycover">
                        <li>
                          <img
                            src={
                              Gender.toLowerCase() === "male"
                                ? "./images/father_img.svg"
                                : "./images/mother_img.svg"
                            }
                          />
                          {fname + " " + lname}
                        </li>
                        <li>Self</li>
                        <li>
                          {date_time_format(
                            employee?.employeeDob,
                            dateFormat,
                            timeZone
                          )}
                        </li>
                        <li>
                          {formatCurrency(policyData?.Userledger?.find((policy:any) => !policy.riderName && policy.memberObjectId ===userId )?.memberContribution ?? 0)}
                        </li>
                        <li>
                          <div id={`_${employee?.employeeStatus}`}>
                            <div
                              className="status_dropdown"
                              id={`_${employee?.employeeStatus}`}
                            >
                              {employee?.employeeStatus === 40 ? (
                                <EnrolmentInitated
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 41 ? (
                                <Enrolled
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 42 ? (
                                <Optout
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 46 ? (
                                <NA
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 60 ? (
                                <HowToRegIcon
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 61 ? (
                                <Requested
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : employee?.employeeStatus === 73 ? (
                                <Requested
                                  id={`_${employee?.employeeStatus?.toString()}`}
                                />
                              ) : null}
                              <span>
                                {
                                  prioritystatusEnrollmentData?.find(
                                    (st: any) =>
                                      st?.label === employee?.employeeStatus
                                  )?.value
                                }
                              </span>

                              {/* <SelectDropdown
                                    class_name="inputFieldd"
                                    title=""
                                    value={employee?.employeeStatus}
                                    attrName={["setPriorityStatus", "member"]}
                                    value_update={updateMasterState}
                                    dropdown_data={prioritystatusEnrollmentData}
                                    warn_status={false}
                                    disabled
                                  /> */}
                            </div>
                          </div>
                        </li>
                      </ul>
                      {policyData?.members?.length > 0 &&
                        policyData.members.map((item: any) => (
                          <ul className="policycover">
                            <li>
                              <img
                                src={
                                  item.relationWithEmployee === "Wife" ||
                                    item.relationWithEmployee === "Mother" ||
                                    item.relationWithEmployee === "Mother-In-Law"
                                    ? "./images/mother_img.svg"
                                    : item.relationWithEmployee === "Husband" ||
                                      item.relationWithEmployee === "Father" ||
                                      item.relationWithEmployee ===
                                      "Father-In-Law"
                                      ? "./images/father_img.svg"
                                      : item.relationWithEmployee === "Son"
                                        ? "./images/boy_img.svg"
                                        : "./images/girl_img.svg"
                                }
                              />
                              {item.fullName}
                            </li>
                            <li>{item?.relationWithEmployee}</li>
                            <li>
                              {date_time_format(
                                item?.dob,
                                dateFormat,
                                timeZone
                              )}
                            </li>
                            <li>
                          {formatCurrency(policyData?.Userledger?.find((policy:any) => !policy.riderName && policy.memberObjectId === item?.dependentId )?.memberContribution ?? 0)}
                        </li>
                            <li>
                              <div id={`_${item.enrolment_status}`}>
                                <div
                                  className="status_dropdown"
                                  id={`_${item.enrolment_status}`}
                                >
                                  {item.enrolment_status === 40 ? (
                                    <EnrolmentInitated
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 41 ? (
                                    <Enrolled
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 42 ? (
                                    <Optout
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 46 ? (
                                    <NA
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 60 ? (
                                    <HowToRegIcon
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 61 ? (
                                    <Requested
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 73 ? (
                                    <Requested
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : null}
                                  <span>
                                    {
                                      prioritystatusEnrollmentData?.find(
                                        (st: any) =>
                                          st?.label === item.enrolment_status
                                      )?.value
                                    }
                                  </span>

                                  {/* <SelectDropdown
                                    class_name="inputFieldd"
                                    title=""
                                    value={item.enrolment_status}
                                    attrName={["setPriorityStatus", "member"]}
                                    value_update={updateMasterState}
                                    dropdown_data={prioritystatusEnrollmentData}
                                    warn_status={false}
                                    disabled
                                  /> */}
                                </div>
                              </div>
                            </li>
                          </ul>
                        ))}
                    </>
                    {policyData?.nominee?.length > 0 && (
                      <>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          className="mt-3"
                        >
                          <h5 style={{ textAlign: "unset" }}>Nominees:</h5>
                        </Box>
                        {policyData.nominee.map((item: any) => (
                          <ul className="policycover">
                            <li>
                              <img
                                src={
                                  item.relation === "Wife" ||
                                    item.relation === "Mother"
                                    ? "./images/mother_img.svg"
                                    : item.relation === "Husband" || item.relation === "Father"
                                      ? "./images/father_img.svg"
                                      : item.relation === "Son"
                                        ? "./images/boy_img.svg"
                                        : "./images/girl_img.svg"
                                }
                              />
                              {item.fullName}
                            </li>
                            <li>{item?.relation}</li>
                            <li>
                              {date_time_format(
                                item?.dob,
                                dateFormat,
                                timeZone
                              )}
                            </li>
                            <li></li>
                            <li>
                              <div id={`_${item.enrolment_status}`}>
                                <div
                                  className="status_dropdown"
                                  id={`_${item.enrolment_status}`}
                                >
                                  {item.enrolment_status === 44 ? (
                                    <Enrolled
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 45 ? (
                                    <Optout
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 63 ? (
                                    <EnrollmentRequested
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : item.enrolment_status === 64 ? (
                                    <EnrollmentRequested
                                      id={`_${item.enrolment_status?.toString()}`}
                                    />
                                  ) : null}

                                  <span>
                                    {
                                      prioritystatusDataNominee?.find(
                                        (st: any) =>
                                          st?.label === item.enrolment_status
                                      )?.value
                                    }
                                  </span>

                                  {/* <SelectDropdown
                                    class_name="inputFieldd"
                                    title=""
                                    value={item.enrolment_status}
                                    attrName={["setPrioritystatusDataNominee", "nominee"]}
                                    value_update={updateMasterState}
                                    dropdown_data={prioritystatusDataNominee}
                                    warn_status={false}
                                    disabled
                                  /> */}
                                </div>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </>
                    )}
                    {policyData?.productType === "Group Term" &&
                      policyData?.riderBenefitsDetailsflat?.length > 0 && (
                        <>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            className="mt-3"
                          >
                            <h5 style={{ textAlign: "unset" }}>Riders:</h5>
                          </Box>
                          {policyData.riderBenefitsDetailsflat?.map(
                            (item: any) => {
                              if (item?.enable) {
                                return (
                                  <ul className="policycover rider_detail">
                                    <li className="rider_name">
                                      {item.label}{" "}
                                    </li>
                                    <li className="amnt">
                                      <h6>
                                        Rider SA{" "}
                                        <p>
                                          {formatCurrency(item?.amount || "0")}
                                        </p>{" "}
                                      </h6>
                                    </li>
                                    <li className="desc">
                                      <h6>
                                        Description <p>{item?.description}</p>{" "}
                                      </h6>
                                    </li>
                                 <li className="amnt">
                                 <h6>
                                 Rider Contribution
                                    <p>{formatCurrency(policyData?.Userledger?.find((policy:any) => policy.riderName && policy.riderName ===item?.label )?.memberContribution ?? 0)}</p>
                                    </h6>
                                 </li>
                                  </ul>
                                );
                              }
                            }
                          )}
                          {/* {policyData.riderBenefitsDetailsGrade?.map(
                            (item: any) => {
                              if (item?.enable) {
                                return (
                                  <ul className="policycover rider_detail">
                                    <li className="rider_name">
                                      {item.label}{" "}
                                    </li>
                                    <li className="amnt">
                                      <h6>
                                        Rider SA{" "}
                                        <p>
                                          {formatCurrency(item?.amount || "0")}
                                        </p>{" "}
                                      </h6>
                                    </li>
                                    <li className="desc">
                                      <h6>
                                        Description <p>{item?.description}</p>{" "}
                                      </h6>
                                    </li>
                        
                                  </ul>
                                );
                              }
                            }
                          )} */}
                        </>
                      )}
                    {policyData?.productType === "Group Term" &&
                      policyData?.riderBenefitsDetailsGrade[0]?.riders?.length >
                      0 && (
                        <>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            className="mt-3"
                          >
                            <h5 style={{ textAlign: "unset" }}>Riders:</h5>
                          </Box>
                          {policyData?.riderBenefitsDetailsGrade[0]?.riders?.map(
                            (item: any) => {
                              if (item?.enable) {
                                return (
                                  <ul className="policycover rider_detail">
                                    <li className="rider_name">
                                      {item.label}{" "}
                                    </li>
                                    <li className="amnt">
                                      <h6>
                                        Rider SA{" "}
                                        <p>
                                          {formatCurrency(item?.amount || "0")}
                                        </p>{" "}
                                      </h6>
                                    </li>
                                    <li className="desc">
                                      <h6>
                                        Description <p>{item?.description}</p>{" "}
                                      </h6>
                                    </li>
                                    <li className="amnt">
                                 <h6>
                                 Rider Contribution
                                    <p>{formatCurrency(policyData?.Userledger?.find((policy:any) => policy.riderName && policy.riderName ===item?.label )?.memberContribution ?? 0)}</p>
                                    </h6>
                                 </li>
                                  </ul>
                                );
                              }
                            }
                          )}
                          {policyData.riderBenefitsDetailsGrade?.map(
                            (item: any) => {
                              if (item?.enable) {
                                return (
                                  <ul className="policycover rider_detail">
                                    <li className="rider_name">
                                      {item.label}{" "}
                                    </li>
                                    <li className="amnt">
                                      <h6>
                                        Rider SA{" "}
                                        <p>
                                          {formatCurrency(item?.amount || "0")}
                                        </p>{" "}
                                      </h6>
                                    </li>
                                    <li className="desc">
                                      <h6>
                                        Description <p>{item?.description}</p>{" "}
                                      </h6>
                                    </li>
                                  </ul>
                                );
                              }
                            }
                          )}
                        </>
                      )}
                  </Grid>
                  <Grid xs={12} className="pl-0 scrollable_item" >
                    <h5>This Policy Covers:</h5>
                    <ul className="category_name">
                      {featureAndExclusion?.map((item: any, i: number) => {
                        return (
                          <li>
                            {item?.featureData?.slice(0, 5)?.map((feaData: any) => {
                              if (policyData?.planType?.name === "FLAT") {
                                return (
                                  <>
                                    <h5>{feaData?.category}</h5>
                                    {feaData?.features?.map((item1: any, ind: number) => {
                                      return item1?.featureDetails ? (
                                        <>
                                          <h6
                                            style={{
                                              color: "#2160aa",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {item1?.featureDetails?.name}
                                          </h6>
                                          <p>
                                            {item1?.value}{" "}
                                            {item1?.featureDetails?.unit ===
                                              "Percentage"
                                              ? "%"
                                              : item1?.featureDetails?.unit ===
                                                "Currency"
                                                ? ""
                                                : item1?.featureDetails?.unit ===
                                                  "Free Text"
                                                  ? item1?.featureDetails
                                                    ?.customUnitType
                                                  : item1?.featureDetails?.unit}
                                          </p>
                                          <p className="mb-2">
                                            <span>{"Description: "} </span>{" "}
                                            {feaData?.description}{" "}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <h6
                                            style={{
                                              color: "#2160aa",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {item1?.name}
                                          </h6>
                                          <p>
                                            {item1?.value}{" "}
                                            {item1?.unit === "Percentage"
                                              ? "%"
                                              : item1?.unit === "Currency"
                                                ? ""
                                                : item1?.unit === "Free Text"
                                                  ? item1?.customUnitType
                                                  : item1?.unit}
                                          </p>
                                          <p className="mb-2">
                                            <span>{"Description: "}</span>{" "}
                                            {item1?.description}{" "}
                                          </p>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <h5>{feaData?.category}</h5>
                                    {feaData?.features?.map((item1: any, ind: number) => {
                                      return item1?.featureDetails ? (
                                        <>
                                          <h6
                                            style={{
                                              color: "#2160aa",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {item1?.featureDetails?.name}
                                          </h6>
                                          <p>
                                            {item1?.value}{" "}
                                            {item1?.featureDetails?.unit ===
                                              "Percentage"
                                              ? "%"
                                              : item1?.featureDetails?.unit ===
                                                "Currency"
                                                ? ""
                                                : item1?.featureDetails?.unit ===
                                                  "Free Text"
                                                  ? item1?.featureDetails
                                                    ?.customUnitType
                                                  : item1?.featureDetails?.unit}
                                          </p>
                                          <p className="mb-2">
                                            <span>{"Description: "} </span>{" "}
                                            {item1?.featureDetails?.description}{" "}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <h6
                                            style={{
                                              color: "#2160aa",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {item1?.name}
                                          </h6>
                                          <p>
                                            {item1?.value}{" "}
                                            {item1?.unit === "Percentage"
                                              ? "%"
                                              : item1?.unit === "Currency"
                                                ? ""
                                                : item1?.unit === "Free Text"
                                                  ? item1?.customUnitType
                                                  : item1?.unit}
                                          </p>
                                          <p className="mb-2">
                                            <span>{"Description: "} </span>{" "}
                                            {item1?.description || feaData?.description}
                                          </p>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })}
                          </li>
                        );
                      })}
                      {/* view all feature button */}
                      {featureAndExclusion?.length > 1 && <Grid xs={12} padding={0}
                        className="ctaBtn"
                      // style={{ textAlign: "center" }}
                      >
                        <Button
                          variant="contained"
                          className="smBtn submitBtn"
                          onClick={() => {
                            setModalKey("features")
                            setViewModal(true)
                          }}
                        >
                          View All Features
                        </Button>
                      </Grid>}
                    </ul>
                  </Grid>
                </Grid>
              </div>
              <div className="exclusion">
                <h5>Exclusions:</h5>
                <ul className="exclusion_content">
                  {featureAndExclusion?.length > 0 &&
                    featureAndExclusion[0]?.exclusion?.slice(0, 5)?.map((data: any) => (
                      <>
                        <h6 style={{ fontWeight: 600 }}>{data?.name || data?.title}</h6>
                        <li>{data?.description}</li>
                      </>
                    ))}
                  {/* view all exclusion button */}
                  {featureAndExclusion[0]?.exclusion?.length > 5 &&
                    <Grid xs={12} padding={0}
                      className="ctaBtn"
                    // style={{ textAlign: "center" }}
                    >
                      <Button
                        variant="contained"
                        className="smBtn submitBtn"
                        onClick={() => {
                          setModalKey("exclusions");
                          setViewModal(true)
                        }}
                      >
                        View All Exclusions
                      </Button>
                    </Grid>}
                </ul>
              </div>
            </Grid>

            <Grid xs={12} md={12} lg={6} className="right_section">
              <NetworkHospital page="contentOnly" tpaName={tpaName} policyNumber={policyNumber} />
            </Grid>
          </Grid>
        </div>
      </Box>
      <Modal open={viewModal} className="modalWrapper">
        <Box className="modalBig">
          <Grid container spacing={1}>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <Link
                className="close-button"
                onClick={() => {
                  setViewModal(false);
                  setModalKey("");
                  setsearchBarFilter("");
                }}
              />
              <SearchBox value_update={updateMasterState} custom={true} />
            </Grid>
            {modalKey === "features" &&
              <Grid xs={12} className="pl-0">
                <h5 className="cover_heading">This Policy Covers:</h5>
                <ul className="category_name">
                  {filteredfeatures?.map((item: any, i: number) => {
                    return (
                      <li>
                        {item?.featureData?.map((feaData: any) => {
                          if (policyData?.planType?.name === "FLAT") {
                            return (
                              <div className="feature_inerlist">
                                <span className="medical"></span>
                                <h5>{feaData?.category}</h5>
                                {feaData?.features?.map((item1: any) => {
                                  return item1?.featureDetails ? (
                                    <>
                                      <h6
                                        style={{
                                          color: "#2160aa",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {item1?.featureDetails?.name}
                                      </h6>
                                      <p>
                                        {item1?.value}{" "}
                                        {item1?.featureDetails?.unit ===
                                          "Percentage"
                                          ? "%"
                                          : item1?.featureDetails?.unit ===
                                            "Currency"
                                            ? ""
                                            : item1?.featureDetails?.unit ===
                                              "Free Text"
                                              ? item1?.featureDetails
                                                ?.customUnitType
                                              : item1?.featureDetails?.unit}
                                      </p>
                                      <p className="mb-2">
                                        <span>{"Description: "} </span>{" "}
                                        {feaData?.description}{" "}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <h6
                                          style={{
                                            color: "#2160aa",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {item1?.name}
                                        </h6>
                                        <p>
                                          {item1?.value}{" "}
                                          {item1?.unit === "Percentage"
                                            ? "%"
                                            : item1?.unit === "Currency"
                                              ? ""
                                              : item1?.unit === "Free Text"
                                                ? item1?.customUnitType
                                                : item1?.unit}
                                        </p>
                                        <p className="mb-2">
                                          <span>{"Description: "}</span>{" "}
                                          {item1?.description}{" "}
                                        </p>
                                      </>
                                    </>
                                  );
                                })}
                              </div>
                            );
                          } else {
                            return (
                              <div className="feature_inerlist">
                                <span className="medical"></span>
                                <h5>{feaData?.category}</h5>
                                {feaData?.features?.map((item1: any) => {
                                  return item1?.featureDetails ? (
                                    <>
                                      <h6
                                        style={{
                                          color: "#2160aa",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {item1?.featureDetails?.name}
                                      </h6>
                                      <p>
                                        {item1?.value}{" "}
                                        {item1?.featureDetails?.unit ===
                                          "Percentage"
                                          ? "%"
                                          : item1?.featureDetails?.unit ===
                                            "Currency"
                                            ? ""
                                            : item1?.featureDetails?.unit ===
                                              "Free Text"
                                              ? item1?.featureDetails
                                                ?.customUnitType
                                              : item1?.featureDetails?.unit}
                                      </p>
                                      <p className="mb-2">
                                        <span>{"Description: "} </span>{" "}
                                        {item1?.featureDetails?.description}{" "}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h6
                                        style={{
                                          color: "#2160aa",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {item1?.name}
                                      </h6>
                                      <p>
                                        {item1?.value}{" "}
                                        {item1?.unit === "Percentage"
                                          ? "%"
                                          : item1?.unit === "Currency"
                                            ? ""
                                            : item1?.unit === "Free Text"
                                              ? item1?.customUnitType
                                              : item1?.unit}
                                      </p>
                                      <p className="mb-2">
                                        <span>{"Description: "} </span>{" "}
                                        {item1?.description ||
                                          feaData?.description}{" "}
                                      </p>
                                    </>
                                  );
                                })}
                              </div>
                            );
                          }
                        })}
                      </li>
                    );
                  })}
                </ul>
              </Grid>}
            {modalKey === "exclusions" &&
              <Grid xs={12} className="exclusion">
                <h5>Exclusions:</h5>
                <ul className="exclusion_content">
                  {filteredExclusions?.length > 0 &&
                    filteredExclusions?.map((data: any) => (
                      <li className="exclusion_list">
                        <div className="exclusion_inerlist">
                          <span className="aids"></span>
                          <div className="exclusion_content">
                            <h5>{data.name || data.title}</h5>
                            <p>{data.description}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Grid>}
          </Grid>
        </Box>
      </Modal >
    </>
  );
}

export default GroupHealthPolicyEmpV2;
