import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button, Link, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import InitiateAClaim from "../../../../Components/Broker/Claim/InitiateAClaimSp/InitiateAClaim";
import ViewDocuments from "../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import AddNewRequest from "../../../../Components/Broker/Endorsment/AddNewRequestSP/AddNewRequest";
import { supporting_document_data } from "../../../../Components/Broker/Endorsment/AddNewRequestSP/dropdownDataJson";
import BulkUploadForm from "../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import { Claim_SERVICES } from "../../../../Services/Claim/ClaimServices";
import { Claim_ASSISTANT } from "../../../../Services/Employee/ClaimAssistantService";
import { ENDORSEMENT_SERVICES } from "../../../../Services/Endorsement/EndorsementService";
import { TUserData } from "../../../../Services/Types/TUserData";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import {
  date_time_format,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  uploadImage,
} from "../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import "./ClaimAssistant.scss";
import ClaimPopup from "./ClaimPopup/ClaimPopup";
import EndorsementDetailPopup from "./EndorsementDetailPopup/EndorsementDetailPopup";
import NewLoader from "../../../../Components/NewLoader/NewLoader";

const ClaimAssistant = () => {
  const { formatCurrency } = useFormatCurrency();
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [addNewRequest, setAddNewRequest] = useState(false);
  const [InitiateClaim, setInitiateClaim] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [claimPopup, setClaimPopup] = useState(false);
  const [endorsementDetailPopup, setEndorsementDetailPopup] = useState(false);
  const [EndorsementDetailData, setEndorsementDetailData] = useState();
  const [ClaimDetailData, setClaimDetailData] = useState<any>({ _id: "" });
  const [docData, setDocData] = useState([]);
  const [prioritystatusData, setprioritystatusData] = useState<
    { label: number; value: string }[]
  >([]);
  const [prioritystatusDataEndorsment, setprioritystatusDataEndorsment] =
    useState<{ label: number; value: string }[]>([]);
  const [selectedIdType, setselectedIdType] = useState<{
    id: string;
    type: string;
  }>({ id: "", type: "" });
  const [endorsement_claim_data, setEndorsement_claim_data] = useState({
    endorsements: [],
    claims: [],
  });
  const [viewEndorsementDoc, setViewEndorsementDoc] = useState(false);
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
    docType: "",
  });
  const [loading, setLoading] = useState(true);

  const updateMasterState = (attrName: any, value: string) => {
    let data: any = displayData;
    if (attrName[0] === "docType") {
      data = { ...data, docType: value, warning: false };
      return setDisplayData(data);
    }

    if (attrName === "attachment_new_data") {
      submit_doc_endorsment_claim(value);
    } else if (attrName === "close_attachment") {
      setViewEndorsementDoc(false);
    } else {
      attrName(value);
    }
  };
  let timeoutId: any;
 
  useEffect(() => {
 
  if (timeoutId) {
      clearTimeout(timeoutId);
    }
 
    const delayedApiCall = () => {
      get_claim_endorsement_data();
      get_status();
      get_status_endorsement();
    }
 
    timeoutId = setTimeout(delayedApiCall, 1500);
 
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  // get endorsement claim data
  const get_claim_endorsement_data = () => {
    const onSuccess = (res: any) => {
      let endorsements = res.data.data.endorsements?.sort((a: any, b: any) => {
        let dateA = new Date(a?.createdAt);
        let dateB = new Date(b?.createdAt);
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      });
      let claims = res.data.data.claims?.sort((a: any, b: any) => {
        let dateA = new Date(a?.createdAt);
        let dateB = new Date(b?.createdAt);
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      });
      setLoading(false);
      setEndorsement_claim_data({
        endorsements: endorsements,
        claims: claims,
      });
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_ASSISTANT.get_emp_enrolment_policies(onSuccess, onError);
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    Claim_SERVICES.get_status(onSuccess, onError);
  };

  const submit_doc_endorsment_claim = (data: any) => {
    let dto = {
      attachments: {
        doc: data.doc,
        doc_name: data.doc_name,
        docType: data.docType,
        description: data.description,
      },
    };
    const onSuccess = (res: any) => {
      toast.success(res.data.message);
      get_claim_endorsement_data();
    };
    const onError = (err: any) => {
      console.log("err", err);
      toast.error("something went wrong...");
    };

    Claim_ASSISTANT.submit_doc_endorsment_claim(
      dto,
      onSuccess,
      {},
      onError,
      selectedIdType.id,
      selectedIdType.type
    );
  };

  const get_status_endorsement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusDataEndorsment(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  function renderEndorsementValue(row: any, data: string) {
    switch (row?.endorsementFor) {
      case "Name":
        return (
          <span>{row?.[data]?.firstName + " " + row?.[data]?.lastName}</span>
        );
      case "Personal Address":
        return <span>{row?.[data]?.street}</span>;
      case "Contact Number":
        return <span>{row?.[data]?.contactNumber}</span>;
      case "Gender":
        return <span>{row?.[data]?.gender}</span>;
      case "Department":
        return <span>{row?.[data]?.department}</span>;
      case "Designation":
        return <span>{row?.[data]?.designation}</span>;
      case "Date of Birth":
        return (
          <span>
            {date_time_format(row?.[data]?.dob, user_type_dateFormat, tz)}
          </span>
        );
      case "Salary":
        return (
          <span>
            {formatCurrency(Number(row?.[data]?.salary.split("/")[0]))}
          </span>
        );
      case "Salary Grade":
        return <span>{row?.[data]?.salary_grade}</span>;
      case "Email":
        return <span>{row?.[data]?.email}</span>; // Assuming you meant "Email" here
      default:
        return null; // or some default value or message
    }
  }

  const submit_endorsement_data = () => {
    let data: any = displayData;
    data.warning = displayData?.doc === "" ? true : false;
    data.warnDescription =
      displayData?.description === "" || displayData?.doc_name === ""
        ? true
        : false;
    setDisplayData({ ...data });
    if (
      displayData.warning === false &&
      displayData.warnDescription === false
    ) {
      setViewEndorsementDoc(false);
      updateMasterState("attachment_new_data", displayData);
      setDisplayData({
        doc: "",
        warning: false,
        description: "",
        warnDescription: false,
        doc_name: "",
      });
    }
  };

  return (
    <>
      {loading && <NewLoader />}
      <AddNewRequest
        attrName={setAddNewRequest}
        open_status={addNewRequest}
        value_update={updateMasterState}
        callApi={get_claim_endorsement_data}
      />
      <InitiateAClaim
        attrName={setInitiateClaim}
        open_status={InitiateClaim}
        value_update={updateMasterState}
        client_id_status={true}
        callApi={get_claim_endorsement_data}
      />
      <BulkUploadForm
        open_status={uploadFile}
        attrName={setUploadFile}
        value_update={updateMasterState}
        closeOnSuccess={true}
      />
      <ClaimPopup
        open_status={claimPopup}
        attrName={setClaimPopup}
        value_update={updateMasterState}
        data={ClaimDetailData}
      />
      <EndorsementDetailPopup
        open_status={endorsementDetailPopup}
        attrName={setEndorsementDetailPopup}
        value_update={updateMasterState}
        data={EndorsementDetailData}
      />
      <Grid xs className="claimAssistant_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12} className="main_heading mb-10">
            <h5>
              Your <span> Requests</span>
            </h5>
            <p>
              An insurance claim is a formal request to your insurance provider
              for reimbursement against losses covered under your insurance
              policy.
            </p>
          </Grid>
          <Grid xs={12}>
            <div className="raise_request">
              <img src="./images/employee_request_img.svg" />
              <h5>Raise New Request</h5>
              <Link
                className="red_border"
                onClick={() => {
                  setInitiateClaim(true);
                }}
              >
                Claim
              </Link>
              <span>or</span>
              <Link
                className="purple_border"
                onClick={() => {
                  setAddNewRequest(true);
                }}
              >
                Endorsement
              </Link>
            </div>
          </Grid>
          {/* pending request section */}

          {/* <Grid
          xs={12}
          className="pending_request_section"
          sx={{ textAlign: "center" }}
        >
          <img
            src="./images/employer_pendingrequest_img.svg"
            alt=""
            height={250}
          />
          <h6>Don't have any pending request </h6>
        </Grid> */}
          {endorsement_claim_data?.claims?.map((data: any) => {
            let relationItem = data?.criteria?.find(
              (item: any) => item.label === "Relationship With Employee"
            )?.value;
            let gender = data?.criteria?.find(
              (item: any) => item.label === "Gender"
            )?.value;
            if (
              relationItem.toLowerCase() === "self" &&
              (gender.toLowerCase() === "male" || !gender)
            ) {
              relationItem = "husband";
            } else if (
              relationItem.toLowerCase() === "self" &&
              gender.toLowerCase() === "female"
            ) {
              relationItem = "wife";
            }
            return (
              <Grid xs={12} className="mb-6">
                <div className="claim_section red_border">
                  <Grid container spacing={3} sx={{ padding: "0" }}>
                    <Grid xs={10} sx={{ padding: "0" }}>
                      <div className="claim_innerSection">
                        <Grid container spacing={3}>
                          <Grid xs={6}>
                            <div className="cashlessClaim">
                              <div className="img_section redbg">
                                <img
                                  src="./images/employer_heart_img.svg"
                                  alt=""
                                />
                              </div>
                              <div className="request_heading">
                                <div>
                                  <h5>
                                    {" "}
                                    Claim{" "}
                                    {data["requestType"].toLowerCase() ===
                                    "intimation"
                                      ? "Reimbursement"
                                      : data["requestType"]}
                                  </h5>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "7px",
                                    }}
                                  >
                                    {data.claimId}
                                  </p>
                                </div>
                                <div id={`_${data.status}`}>
                                  <div
                                    className="status_dropdown"
                                    id={`_${data.status}`}
                                  >
                                    {data.status === 47 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 48 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 49 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 50 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 51 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 52 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 53 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 65 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 66 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 67 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 68 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 69 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 70 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusData?.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <span className={data.status === "Approved" ? "accepted" :
                                  data.status === "Rejected" ? "rejected" :
                                    // data.status === "Pending With Insurer" || "Sent_To_Insurer" ? "pending" :
                                    data.status === 49 || "Information Required" || "Information Required" ? "IR" :
                                      data.status === 47 || "Requested" ? "requested" :
                                        "inprogress"
                                }
                                >{data.status}
                                  {prioritystatusData?.find((item: any) => item.label === data.status)?.value}
                                </span> */}
                                <Button
                                  className="view_btn"
                                  onClick={() => {
                                    setClaimPopup(true);
                                    setClaimDetailData(data);
                                  }}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          <Grid xs={6}>
                            <div className="hospitalised_person">
                              <h6>
                                {data?.memberId} - {data?.memberName}
                              </h6>
                              <div
                                className={`${relationItem.toLowerCase()} user_img`}
                              ></div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="policy_detail pl-6">
                        <h5>{data?.policyType}</h5>
                        <ul>
                          <li>
                            <span>Policy No.</span>
                            {data?.policyNumber}
                          </li>
                          <li>
                            <span>Admission Date</span>
                            {date_time_format(
                              data.admisionDate,
                              user_type_dateFormat,
                              userState.localInformation.timeZone
                            )}
                          </li>
                          <li>
                            <span>Reason for Hospitalisation</span>
                            {data.reason}
                          </li>
                          <li className="attachicon">
                            <Link
                              className="greenBtn addfile"
                              style={{ paddingTop: "20px" }}
                              onClick={() => {
                                setselectedIdType({
                                  id: data._id,
                                  type: "CLAIM",
                                });
                                setUploadFile(true);
                              }}
                            ></Link>
                            <Link>
                              <AttachFileIcon
                                onClick={() => {
                                  if (data.upload.length > 0) {
                                    setViewDocuments(true);
                                    setDocData(data?.upload);
                                  }
                                }}
                              />{" "}
                              {data.upload.length}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="hospital_detail">
                        <ul>
                          <li>
                            <span>Hospital Name : </span> {data.hospitalName}
                          </li>
                          <li>
                            <span>State : </span> {data.state}
                          </li>
                          <li>
                            <span>City : </span> {data.city}
                          </li>
                          <li>
                            <span>Claim Amount : </span>
                            {formatCurrencyAccToUser(
                              data?.claimAmt,
                              getCurrencyCodeRegex(
                                userState.localInformation.currency
                              ),
                              userState.localInformation.countryCurrencyCode,
                              0
                            )}
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid
                      xs={2}
                      sx={{
                        padding: "0",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div className="requested_date_section">
                        <h5>
                          <span> Request Date</span>{" "}
                          {date_time_format(
                            data.createdAt,
                            user_type_dateFormat,
                            userState.localInformation.timeZone
                          )}
                        </h5>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
          {endorsement_claim_data?.endorsements?.map((data: any) => {
            //
            let relationItem = data?.criteria?.find(
              (item: any) => item.label === "Relationship With Employee"
            )?.value;
            let gender = data?.criteria?.find(
              (item: any) => item.label === "Gender"
            )?.value;
            if (
              relationItem.toLowerCase() === "self" &&
              (gender.toLowerCase() === "male" || !gender)
            ) {
              relationItem = "husband";
            } else if (
              relationItem.toLowerCase() === "self" &&
              gender.toLowerCase() === "female"
            ) {
              relationItem = "wife";
            }
            return (
              <Grid xs={12} className="mb-6">
                <div className="claim_section blue_border">
                  <Grid container spacing={3} sx={{ padding: "0" }}>
                    <Grid xs={10} sx={{ padding: "0" }}>
                      <div className="claim_innerSection">
                        <Grid container spacing={3}>
                          <Grid xs={6}>
                            <div className="cashlessClaim">
                              <div className="img_section redbg">
                                <img
                                  src="./images/employer_heart_img.svg"
                                  alt=""
                                />
                              </div>
                              <div className="request_heading">
                                <div>
                                  <h5>Endorsement Request</h5>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "7px",
                                    }}
                                  >
                                    {data.endorsementId}
                                  </p>
                                </div>

                                <div id={`_${data.status}`}>
                                  <div
                                    className="status_dropdown"
                                    id={`_${data.status}`}
                                  >
                                    {data.status === 54 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 55 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 56 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 57 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 58 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 59 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 71 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : data.status === 72 ? (
                                      <span id={`_${data.status.toString()}`}>
                                        {
                                          prioritystatusDataEndorsment.find(
                                            (item: any) =>
                                              item.label === data.status
                                          )?.value
                                        }
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <Button
                                  className="view_btn"
                                  onClick={() => {
                                    setEndorsementDetailPopup(true);
                                    setEndorsementDetailData(data);
                                  }}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          <Grid xs={6}>
                            <div className="hospitalised_person">
                              <h6>
                                {data?.memberId} - {data?.memberName}
                              </h6>
                              <div
                                className={`${relationItem.toLowerCase()} user_img`}
                              ></div>
                              {/* <img
                              src="./images/claim_member_img.svg"
                              alt=""
                              height={50}
                            /> */}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="policy_detail pl-6">
                        <h5>{data?.policyType}</h5>
                        <ul>
                          <li>
                            <span>Policy No.</span>
                            {data?.policyNumber}
                          </li>
                          <li>
                            <span>Endorsment For</span>
                            {data.endorsementFor}
                          </li>
                          {data?.endorsementFor.includes("Member") ||
                          data?.endorsementFor.includes("Nominee") ? (
                            <li>
                              <span>Policy Type</span>
                              {data.policyType}
                            </li>
                          ) : (
                            <li>
                              <span>
                                Old Value :{" "}
                                {data?.endorsementFor === "Date of Birth"
                                  ? date_time_format(
                                      data.old,
                                      user_type_dateFormat,
                                      tz
                                    )
                                  : data.old}
                              </span>
                              New Value : {renderEndorsementValue(data, "new")}
                            </li>
                          )}
                          <li className="attachicon">
                            <Link
                              className="greenBtn addfile"
                              style={{ paddingTop: "20px" }}
                              onClick={() => {
                                setselectedIdType({
                                  id: data._id,
                                  type: "ENDORSEMENT",
                                });
                                // setUploadFile(true);
                                setViewEndorsementDoc(true);
                              }}
                            ></Link>
                            <Link marginRight={"15px"}>
                              <AttachFileIcon
                                onClick={() => {
                                  if (data?.docCount > 0) {
                                    setViewDocuments(true);
                                    setDocData(data?.attachments);
                                  }
                                }}
                              />
                              {data?.docCount}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid
                      xs={2}
                      sx={{
                        padding: "0",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div className="requested_date_section">
                        <h5>
                          <span> Request Date</span>
                          {date_time_format(
                            data.createdAt,
                            user_type_dateFormat,
                            userState.localInformation.timeZone
                          )}
                        </h5>
                      </div>
                      {/* <div className="icon_list">
                      <div>
                        <Link className="redBtn delete ml-0"></Link>
                      </div>
                      <div className="mt-11">
                        <Link>
                          <img
                            src="./images/employer_message_icon.svg"
                            alt=""
                            height={30}
                          />
                        </Link>
                      </div>
                    </div> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <ViewDocuments
          attrName={setViewDocuments}
          open_status={viewDocuments}
          value_update={updateMasterState}
          data={docData}
        />
        <SlidingPanel
          type={"right"}
          isOpen={viewEndorsementDoc}
          size={75}
          panelClassName="sliding-panel"
        >
          <div>
            <Grid container spacing={3} alignItems="center">
              <Grid xs={10}>
                <h4 className="mb-0 mt-3 text-left">Upload your Document</h4>
              </Grid>
              <Grid xs={2} textAlign={"right"}>
                <GridCloseIcon
                  onClick={() => {
                    updateMasterState("close_attachment", "false");
                    setselectedIdType({ id: "", type: "" });
                    setDisplayData({
                      doc: "",
                      warning: false,
                      description: "",
                      warnDescription: false,
                      doc_name: "",
                      docType: "",
                    });
                  }}
                />
              </Grid>
              <Grid xs={12} className="mb-4">
                <hr />
              </Grid>
            </Grid>
            <div className="scrollable_area">
              <div>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Supporting Documents"
                    value={displayData.docType}
                    attrName={["docType", "supportDoc"]}
                    value_update={updateMasterState}
                    dropdown_data={supporting_document_data}
                    warn_status={displayData.warning}
                  />
                </Grid>
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  className="mb-5"
                >
                  <Grid xs={10} className="inputField uploadFile">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Upload .xl .csv .xls .pdf file"
                      variant="outlined"
                      className="textarea"
                      value={displayData.doc_name}
                      disabled
                    />
                  </Grid>
                  <Grid xs={2}>
                    <Button
                      variant="contained"
                      className="browsebtn"
                      component="label"
                    >
                      Browse
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        style={{ zIndex: "99" }}
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          const fileSize = file?.size;
                          const maxSizeBytes = 8 * 1024 * 1024;
                          if (fileSize && fileSize > maxSizeBytes) {
                            toast.warn("File size exceeds 8 MB limit");
                            return (e.target.value = null);
                          }
                          uploadImage(
                            file,
                            (base64Image: any, base64String: any) => {
                              setDisplayData((prevDisplayData: any) => ({
                                ...prevDisplayData,
                                warnDescription:
                                  base64Image === "" ||
                                  prevDisplayData.data === ""
                                    ? true
                                    : false,
                                doc: base64Image,
                                doc_name: e.target.files[0]?.name,
                                description: prevDisplayData.data,
                              }));
                              // doc: "",
                              // warning: false,
                              // description: "",
                              // warnDescription: false,
                              // doc_name: "",
                              // docType: "",
                            }
                          );
                        }}
                      />
                    </Button>
                  </Grid>
                  <Grid xs={12} textAlign={"right"}>
                    {displayData.warnDescription === true && (
                      <span
                        className="textAreaerror"
                        style={{ color: "#ec3f3f", fontSize: "11px" }}
                      >
                        Upload File
                      </span>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <div className="uploadAttachmentInfo">
                      <span>Maximum size of document is 8 MB*</span>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid
                    xs={12}
                    className="ctaBtn"
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      className="submitBtn"
                      onClick={submit_endorsement_data}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </SlidingPanel>
      </Grid>
    </>
  );
};

export default ClaimAssistant;
