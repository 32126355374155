//used
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import BulkUploadForm from "../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import MassUpdate from "../../../../Components/Common/MassUpdate/MassUpdate";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { TUserData } from "../../../../Services/Types/TUserData";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
import {
  convertMultipleNumbersToCurrencyFormat,
  date_time_format,
  getCurrencyCodeRegex,
  getFileSignedUrl,
} from "./../../../../Supporting files/HelpingFunction";

interface Props {
  addClient?: boolean;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}
const Clients: React.FC<Props> = ({
  addClient,
  shortcutDisable,
  setShortcutDisable,
}) => {
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const { timeZone, dateFormat, currency, countryCurrencyCode } =
    useAppSelector(
      (state: RootState) => state.userDetailsSlice.localInformation
    );
  const [addFilterStatus, setAddFilterStatus] = useState(false);
  const [addNewClient, setAddNewClient] = useState(addClient);
  const [uploadFile, setUploadFile] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [policyname, setPolicyName] = useState("");
  const [expiry, setExpiryStatus] = useState("");
  const [massupdateopen, setmassupdateopen] = useState(false);
  const [age, setAge] = useState("");
  const [states, setStates] = useState("");
  const [gender, setGender] = useState("");
  const [headerData, setHeaderData] = useState<Array<{}>>([]);
  const [tableData, setTableData] = useState<Array<{}>>([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [editStatusStep, setEditStatusStep] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [searchBarFilter, setsearchBarFilter] = useState("");
  let selected_data: Array<{ id: number }> = [];
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const navigate = useNavigate();
  const { BROKER } = useAppSelector(
    (state: RootState) => state.permissionSlice
  );
  const [clientBulkUplodPermission, setClientBulkUplodPermission] =
    useState(false);
  const [clientEiditPermission, setClientEiditPermission] = useState(false);
  const [clientAddPermission, setClientAddPermission] = useState(false);
  const [clientRemovePermission, setClientRemovePermission] = useState(false);
  const [clientCsvPermission, setClientCsvPermission] = useState(false);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [csvHeader, setCsvHeader] = useState<any[]>([]);
  const [isClientAssigned, setIsClientAssigned] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    getRowData(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    getRowData(tableLimit ?? 25, tableSkip ?? 0, "csv");
    handleClose();
  };

  useEffect(() => {
    let stopLoop = false;
    array_delete?.forEach((item: any, index: number) => {
      const onSuccess = (res: any) => {
        if (!stopLoop) {
          setIsClientAssigned(res.data.data.founded);
          stopLoop = res.data.data.founded;
        }
      };

      const onError = (err: any) => {
        console.log("err", err);
      };

      if (!stopLoop) {
        CLIENT_SERVICES.check_isclient_attached_toPolicy(
          onSuccess,
          onError,
          item
        );
      }
    });
  }, [array_delete]);
  let timeout: any;

  useEffect(() => {
    setClientBulkUplodPermission(
      BROKER?.Clients?.upload === undefined ? false : BROKER.Clients?.upload
    );
    setClientEiditPermission(
      BROKER?.Clients?.edit === undefined ? false : BROKER.Clients?.edit
    );
    setClientAddPermission(
      BROKER?.Clients?.add === undefined ? false : BROKER.Clients?.add
    );
    setClientRemovePermission(
      BROKER?.Clients?.remove === undefined ? false : BROKER.Clients?.remove
    );
    setClientCsvPermission(
      BROKER?.Clients?.csv === undefined ? false : BROKER.Clients?.csv
    );

    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      getRowData();
      getheaderDataList();
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    if (deleteStatus) {
      window.addEventListener("keydown", handleKeyPress);
      getheaderDataList();
    }
  }, [deleteStatus]);

  const customComparator = (v1: any, v2: any) => {
    const regex = /(\D+)(\d+)/;
    const [, a1, n1] = v1.match(regex);
    const [, a2, n2] = v2.match(regex);

    if (a1 === a2) {
      return parseInt(n1, 10) - parseInt(n2, 10);
    }
    return a1.localeCompare(a2);
  };

  // get header list dynamic
  const getheaderDataList = (statusData?: any) => {
    const onSuccess = (res: any) => {
      setCsvHeader(res.data.data);
      let data: any = [];
      res.data.data.forEach((e: any, index: number) => {
        if (index === 5) {
          data.push({
            field: "status",
            headerName: "Status",
            minWidth: 200,
            check_status: true,
            valueGetter: (params: any) => {
              const { row } = params;
              if (row.status === 17) {
                return "Created";
              } else if (row.status === 18) {
                return "Active";
              } else if (row.status === 19) {
                return "Inactive";
              }
              return ""; // Default case for unknown status
            },
            renderCell: (ValueFormatterParams: any) => {
              const { row, index } = ValueFormatterParams;
              return (
                <div id={`_${row.status}`}>
                  <div className="status_dropdown" id={`_${row.status}`}>
                    {row.status === 17 ? (
                      <PostAddIcon id={`_${row.status.toString()}`} />
                    ) : row.status === 18 ? (
                      <CheckCircleIcon id={`_${row.status.toString()}`} />
                    ) : row.status === 19 ? (
                      <HighlightOffIcon id={`_${row.status.toString()}`} />
                    ) : null}
                    {row.status === 17 ? (
                      <span>Created</span>
                    ) : row.status === 18 ? (
                      <span>Active</span>
                    ) : row.status === 19 ? (
                      <span>Inactive</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            },
          });
        }
        if (e.field === "Organisation" || e.field === "Organization") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${ALL_ROUTES.CLIENT_DETAIL_VIEW}?id=${row.id}`);
                  }}
                >
                  {row["Organisation"] || row["Organization"]}
                </Link>
              );
            },
          });
        } else if (e.field === "ID") {
          data.push({
            field: "ID",
            headerName: "ID",
            width: 150,
            sortComparator: customComparator,
          });
        } else if (e.field === "Organization Email") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  href={`mailto:${row["Organization Email"]}`}
                  title={row["Organization Email"]}
                >
                  {row["Organization Email"].length > 25
                    ? row["Organization Email"].substring(0, 25) + "..."
                    : row["Organization Email"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Salary Brackets") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              const salaryBracket = () => {
                const lowerUpper = convertMultipleNumbersToCurrencyFormat(
                  row["Salary Brackets"],
                  getCurrencyCodeRegex(userState.localInformation.currency),
                  userState?.localInformation?.countryCurrencyCode,
                  true
                );
                const splitVals = lowerUpper.split("-");
                return lowerUpper;
              };
              return <span>{row["Salary Brackets"] && salaryBracket()}</span>;
            },
          });
        } else if (e.field === "Designations") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              const Designations = () => {
                let data = row["Designations"];
                let splitted = data.split(",");
                if (splitted.length > 2) {
                  return `${splitted[0]},${splitted[1]}...`;
                } else {
                  return row["Designations"];
                }
              };
              return <span>{row["Designations"] && Designations()}</span>;
            },
          });
        } else if (e.field === "Departments") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              const Departments = () => {
                let data = row["Departments"];
                let splitted = data.split(",");
                if (splitted.length > 2) {
                  return `${splitted[0]},${splitted[1]}...`;
                } else {
                  return row["Departments"];
                }
              };
              return <span>{row["Departments"] && Departments()}</span>;
            },
          });
        } else if (e.field === "Location Name") {
          data.push({
            ...e,
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              
              // Return the comma-separated location names for sorting and filtering
              return row["locationNames"] ? row["locationNames"].join(", ") : "";
            },
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              
              // Ensure row["locationNames"] is defined and an array
              const locationName = row["locationNames"] ? row["locationNames"].join(", ") : "";
          
              return <span>{locationName}</span>;
            },
          });
        } else if (e.field === "Location Type") {
          data.push({
            ...e,
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              
              // Return the comma-separated location types for sorting and filtering
              return row["locationTypes"] ? row["locationTypes"].join(", ") : "";
            },
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              
              // Ensure row["locationTypes"] is defined and an array
              const locationName = row["locationTypes"] ? row["locationTypes"].join(", ") : "";
          
              return <span>{locationName}</span>;
            },
          });
        } else if (e.field === "Website URL") {
          data.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(`https://${row["Website URL"]}`, "_blank");
                  }}
                >
                  {row["Website URL"]}
                </Link>
              );
            },
          });
        } else {
          data.push(e);
        }
      });
      setHeaderData(
        data.concat(
          {
            field: "createdAt",
            minWidth: 200,
            check_status: true,
            headerName: "Created On",
            valueGetter: (params: any) => {
              // Format the date here for filtering and sorting
              return date_time_format(
                params.row.createdAt,
                user_type_dateFormat,
                userState.localInformation.timeZone
              );
            },
          },
          {
            field: "updatedAt",
            minWidth: 200,
            check_status: true,
            headerName: "Updated On",
            valueGetter: (params: any) => {
              return date_time_format(
                params.row.updatedAt,
                user_type_dateFormat,
                userState.localInformation.timeZone
              );
            },
          }
        )
      );
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_header_field(onSuccess, onError);
  };

  //get client row data dynamic
  const getRowData = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      if (csv) {
        return downloadCsv(res.data.data.data);
      }
      setTableData(res.data.data.data);
      setTotalRecords(res.data.data.countData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const currentRoute = E_BE_FILTER_CODE.CLIENT.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    CLIENT_SERVICES.get_client_row_data(
      onSuccess,
      onError,
      limit ? limit : existingEntryIndex?.pageNumber ?? 25,
      skip ? skip : 0,
      startEndDate.startDate ? startEndDate.startDate : "",
      startEndDate.endDate ? startEndDate.endDate : "",
      searchBarFilter || ""
    );
  };

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditStatus(true);
    } else if (array_delete.length > 1) {
      setEditStatus(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_CLIENT}?id=${array_delete[0]}`);
    }
  };

  //delete clients
  const delete_client = () => {
    let data: any = {
      ids: array_delete,
    };
    const onSuccess = (res: any) => {
      getRowData(tableLimit, tableSkip);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.delete_clients(data, onSuccess, onError);
    setDeleteStatusStep(2);
  };

  const deleteditem = () => {
    setEditStatusStep(1);
    setDeleteStatusStep(1);
    setDeleteStatus(false);
  };

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    if (event.target.tagName !== "INPUT") {
      if (event.shiftKey && event.keyCode === 67) {
        setAddNewClient(true);
      }
    }
  }, []);

  const handleChange = (event: SelectChangeEvent, attrName: string) => {
    if (attrName === "gender") {
      setGender(event.target.value as string);
    } else if (attrName === "policyType") {
      setPolicyType(event.target.value as string);
    } else if (attrName === "expiry") {
      setExpiryStatus(event.target.value as string);
    } else if (attrName === "policyname") {
      setPolicyName(event.target.value as string);
    } else if (attrName === "states") {
      setStates(event.target.value as string);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
      setArray_Delete(value);
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else if (attrName === "massupdateopen") {
      setmassupdateopen(false);
      getRowData(tableLimit || 25, 0);
    } else {
      attrName(value);
    }
    // setArray_Delete(value);
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      getRowData();
      getheaderDataList();

    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter, startEndDate]);

  // useEffect(() => {
  //   getRowData();
  // }, [startEndDate]);

  const downloadCsv = (data: any) => {
    const onSuccess = (res: any) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "clients.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const updatedArr = data?.map((item: any) => {
      if (item.status === 17) {
        return {
          ...item,
          status: "Created",
          createdAt: date_time_format(item?.createdAt, dateFormat, timeZone),
          updatedAt: date_time_format(item?.updatedAt, dateFormat, timeZone),
          "Salary Brackets":
            item["Salary Brackets"] !== "" &&
              item["Salary Brackets"] !== undefined
              ? convertMultipleNumbersToCurrencyFormat(
                item["Salary Brackets"],
                getCurrencyCodeRegex(currency),
                countryCurrencyCode,
                true
              ).replace(/₹/g, "Rs")
              : item["Salary Brackets"],
          "Location Name": item.locationNames?.join(","),
          "Location Type": item.locationTypes?.join(","),
        };
      } else if (item.status === 18) {
        return {
          ...item,
          status: "Active",
          createdAt: date_time_format(item?.createdAt, dateFormat, timeZone),
          updatedAt: date_time_format(item?.updatedAt, dateFormat, timeZone),
          "Salary Brackets":
            item["Salary Brackets"] !== "" &&
              item["Salary Brackets"] !== undefined
              ? convertMultipleNumbersToCurrencyFormat(
                item["Salary Brackets"],
                getCurrencyCodeRegex(currency),
                countryCurrencyCode,
                true
              ).replace(/₹/g, "Rs")
              : item["Salary Brackets"],
          "Location Name": item.locationNames?.join(","),
          "Location Type": item.locationTypes?.join(","),
        };
      } else if (item.status === 19) {
        return {
          ...item,
          status: "InActive",
          createdAt: date_time_format(item?.createdAt, dateFormat, timeZone),
          updatedAt: date_time_format(item?.updatedAt, dateFormat, timeZone),
          "Salary Brackets":
            item["Salary Brackets"] !== "" &&
              item["Salary Brackets"] !== undefined
              ? convertMultipleNumbersToCurrencyFormat(
                item["Salary Brackets"],
                getCurrencyCodeRegex(currency),
                countryCurrencyCode,
                true
              ).replace(/₹/g, "Rs")
              : item["Salary Brackets"],
          "Location Name": item.locationNames?.join(","),
          "Location Type": item.locationTypes?.join(","),
        };
      }
      return {
        ...item,
        "Salary Brackets":
          item["Salary Brackets"] !== "" &&
            item["Salary Brackets"] !== undefined
            ? convertMultipleNumbersToCurrencyFormat(
              item["Salary Brackets"],
              getCurrencyCodeRegex(currency),
              countryCurrencyCode,
              true
            ).replace(/₹/g, "Rs")
            : item["Salary Brackets"],
        "Location Name": item.locationNames?.join(","),
        "Location Type": item.locationTypes?.join(","),
      };
    });

    let dto = {
      headers: headerData,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userState.userType.toLowerCase(),
      "client"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={2} sx={{ paddingBottom: "50px" }}>
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        <MassUpdate
          open_status={massupdateopen}
          value_update={updateMasterState}
          selected_id={array_delete}
          get_Api_url={CLIENT_SERVICES.massModification}
          update_Api_url={CLIENT_SERVICES.updateMassmodification}
          status={true}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
          forComponent="Client"
        />

        {/* Filter */}
        <Modal open={addFilterStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddFilterStatus(false);
                  }}
                />
                <h5 className="popup-heading">Add Filters</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Client ID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Client ID"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>001</MenuItem>
                    <MenuItem value={"22"}>002</MenuItem>
                    <MenuItem value={"24"}>003</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Type"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Client Name"
                  variant="outlined"
                  className="textarea"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    CD Balance
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="CD Balance"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">SPOC</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="SPOC"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="  Status"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Active Policies
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label=" Active Policies"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Total Employees
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label=" Total Employees"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center" }}
                className="ctaBtn"
              >
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    setAddFilterStatus(false);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Clients</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid
          item
          xs={7}
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"end"}
          className="text-right"
          alignItems={"center"}
        >
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {clientCsvPermission && tableData?.length > 0 && (
            <>
              <Link
                underline="none"
                className="blueBtn download"
                style={{ cursor: "pointer" }}
                onClick={handleClick}
              >
                Download CSV
              </Link>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          {clientAddPermission && array_delete.length > 1 && (
            <Link
              underline="none"
              className="greenBtn edit"
              onClick={() => {
                setmassupdateopen(true);
              }}
            >
              Mass Update
            </Link>
          )}
          {clientBulkUplodPermission && (
            <Link
              underline="none"
              className="blueBtn upload"
              onClick={() => {
                navigate({
                  pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                  search: createSearchParams({
                    calledFrom: ALL_ROUTES.CLIENTS,
                  }).toString(),
                });
              }}
            >
              Bulk Upload
            </Link>
          )}
          {clientEiditPermission && array_delete.length > 0 && (
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {!isClientAssigned &&
            clientRemovePermission &&
            array_delete.length > 0 && (
              <Link
                underline="none"
                className="redBtn delete"
                onClick={() => {
                  setDeleteStatus(true);
                }}
              >
                Remove
              </Link>
            )}
          {clientAddPermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_CLIENT);
              }}
            >
              Add Client
            </Link>
          )}
        </Grid>

        <Grid item xs={12}>
          <UGTable
            header_data={headerData}
            data={tableData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.CLIENT}
            refetch_data={getRowData}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>

        {/* Remove pop up */}
        <Modal open={deleteStatus} className="modalWrapper">
          <>
            {deleteStatusStep === 1 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_deleteicon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <h5 className="popup-heading">
                        Are you sure you want
                        <br /> to delete this list?
                      </h5>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link mb-10" underline="none">
                      {array_delete?.length === 0
                        ? "Please Select the Client"
                        : `You are selected ${array_delete?.length} to delete`}
                    </Link>
                  </Grid>
                  {array_delete?.length === 0 ? (
                    ""
                  ) : (
                    <Grid
                      xs={12}
                      className="ctaBtn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={delete_client}
                      >
                        Yes, Delete
                      </Button>
                      <Button
                        onClick={() => setDeleteStatus(false)}
                        variant="contained"
                        className="cancelBtn"
                      >
                        cancel
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : deleteStatusStep === 2 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletePopup_tickicon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    <h5 className="popup-heading">Deleted Successfully!</h5>
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link mb-10" underline="none">
                      Your Client has been Deleted
                    </Link>
                  </Grid>
                  <Grid
                    xs={12}
                    className="ctaBtn"
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      onClick={deleteditem}
                      variant="contained"
                      className="submitBtn"
                    >
                      Okay
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        </Modal>

        {/* Edit pop up */}
        <Modal open={editStatus} className="modalWrapper">
          <>
            {editStatusStep === 1 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setEditStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_edit_icon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <h5 className="popup-heading">
                        You can't select multiple ids
                      </h5>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link mb-10" underline="none">
                      {array_delete?.length === 0
                        ? "Please Select the Client for edit"
                        : `You have selected ${array_delete?.length} ids`}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        </Modal>
      </Grid>
    </>
  );
};

export default Clients;
