import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
interface Props {
  attrName: any;
  title: string;
  value: any;
  value_update: Function;
  multiple_data: Array<any>;
  info_status?: boolean;
  info_message?: string;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  required?: boolean;
  disabled?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: [],
  attrName: "",
  value_update: () => { },
  multiple_data: [],
};

const MultipleSearchDropdown: React.FC<Props> = ({
  attrName,
  title,
  value,
  multiple_data,
  class_name,
  value_update,
  info_status,
  info_message,
  error_messg,
  warn_status,
  required,
  disabled,
}) => {
  return (
    <div className={class_name}>
      {value.label ? (
        <Autocomplete
          multiple
          value={value}
          options={multiple_data}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            option.value || option.name || option.label || ""
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}             >{option.key || option.label || option.name}</li>
          )}
          onChange={(event, new_team) => {
            value_update(attrName, new_team);
            attrName = "";
          }}
          renderInput={(params) => (
            <TextField {...params} label={title} required={required} />
          )}
          disabled={disabled}
        />
      ) : (
        <Autocomplete
          multiple
          value={value?.filter(
            (item: any) => item.name || item.label || item.key || item.id
          )?.map((item: any) => {
            return {
              id: item.id ? item.id : "",
              key: item.key || item.label || item.name,
              value: item.label || item.key || item.name,
              type: item.type ? item.type : "",
            };
          })}
          options={multiple_data}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            option.value || option.name || option.label || ""
          }
          isOptionEqualToValue={(option: any, value: any) => {
            if (
              (option.label || option.key || option.name) ===
              (value.label || value.key || value.name)
            ) {
              return true;
            } else {
              return false;
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}             >{option.key || option.label || option.name}</li>
          )}
          onChange={(event, new_team) => {
            value_update(attrName, new_team);
            attrName = "";
          }}
          renderInput={(params) => (
            <TextField {...params} label={title} required={required} className="multi_inputs"
            />
          )}
          disabled={disabled}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

MultipleSearchDropdown.defaultProps = defaultProps;
export default MultipleSearchDropdown;
