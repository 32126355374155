import Renewed from "@mui/icons-material/Autorenew";
import Inforced from "@mui/icons-material/Beenhere";
import Draft from "@mui/icons-material/BookmarkAdd";
import Rejected from "@mui/icons-material/Cancel";
import Cancelled from "@mui/icons-material/CancelPresentation";
import Active from "@mui/icons-material/CheckCircle";
import Void from "@mui/icons-material/DoDisturbOff";
import Expired from "@mui/icons-material/GppBad";
import Inactive from "@mui/icons-material/HighlightOff";
import New from "@mui/icons-material/NewReleases";
import Renewal from "@mui/icons-material/Sync";
import Grace from "@mui/icons-material/TrendingUp";
import { Link, Menu, MenuItem, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { EMPLOYER_POLICIES_SERVICES } from "../../../../Services/EmployerServices/Policies/PoliciesServices";
import { useAppSelector } from "../../../../Store/hooks";
import {
  date_time_format,
  formatSumInsured,
} from "../../../../Supporting files/HelpingFunction";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";

export default function Policies() {
  const { formatCurrency } = useFormatCurrency();
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const { dateFormat, timeZone } = useAppSelector(
    (state) => state.userDetailsSlice.localInformation
  );
  const navigate = useNavigate();
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [downloadCsvPermission, setDownloadCsvPermission] = useState(false);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    setDownloadCsvPermission(
      EMPLOYER?.Policies?.csv === undefined ? false : EMPLOYER?.Policies?.csv
    );
  }, []);

  const [policyData, setPolicyData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [columns, setColumns] = useState([
    {
      field: "policyId",
      headerName: "Policy ID",
      width: 170,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            underline="none"
            style={{
              cursor: "pointer",
              fontWeight: "400",
              color: "#2160aa",
              textDecoration: "none",
            }}
            onClick={() => {
              navigate(`${EMPLOYER_ALL_ROUTES.POLICY_DETAILS}?id=${row._id}`);
            }}
          >
            {row["policyId"]}
          </Link>
        );
      },
    },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 220,
      check_status: true,
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: 150,
      check_status: true,
    },
    {
      field: "policyType",
      headerName: "Policy Type",
      width: 200,
      check_status: true,
    },
    {
      field: "insurerDetails",
      headerName: "Insurer",
      width: 180,
      check_status: true,
    },
    {
      field: "planType",
      headerName: "Plan Type",
      width: 100,
      check_status: true,
    },
    {
      field: "policyNumber",
      headerName: "Policy Number",
      width: 180,
      check_status: true,
    },
    {
      field: "status",
      headerName: "Policy Status",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row, index } = ValueFormatterParams;
        return (
          <div id={`_${row.status.value}`}>
            <div className="status_dropdown" id={`_${row.status.value}`}>
              {row.status.value === 20 ? (
                <Draft id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 21 ? (
                <Inforced id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 22 ? (
                <Expired id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 23 ? (
                <Renewed id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 24 ? (
                <Void id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 25 ? (
                <Cancelled id={`_${row.status.value.toString()}`} />
              ) : row.status.value === 26 ? (
                <Rejected id={`_${row.status.value.toString()}`} />
              ) : null}
              <span>{row.status.label}</span>
            </div>
          </div>
        );
      },
    },
    {
      field: "Enrollmentstatus",
      headerName: "Enrollment Status",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row, index } = ValueFormatterParams;
        return (
          <div id={`_${row?.enrolment_status?.value}`}>
            <div
              className="status_dropdown"
              id={`_${row?.enrolment_status?.value}`}
            >
              {row?.enrolment_status?.value === 27 ? (
                <Active id={`_${row?.enrolment_status?.value.toString()}`} />
              ) : row?.enrolment_status?.value === 28 ? (
                <Inactive id={`_${row?.enrolment_status?.value.toString()}`} />
              ) : row?.enrolment_status?.value === 29 ? (
                <Grace id={`_${row?.enrolment_status?.value.toString()}`} />
              ) : null}
              <span>{row?.enrolment_status?.label}</span>
            </div>
          </div>
        );
      },
    },
    {
      field: "B.Type",
      headerName: "B.Type",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row, index } = ValueFormatterParams;
        return (
          <div id={`_${row?.btype_status?.value}`}>
            <div
              className="status_dropdown"
              id={`_${row?.btype_status?.value}`}
            >
              {row?.btype_status?.value === 30 ? (
                <New id={`_${row?.btype_status?.value.toString()}`} />
              ) : row?.btype_status?.value === 31 ? (
                <Renewal id={`_${row?.btype_status?.value.toString()}`} />
              ) : null}
              <span>{row?.btype_status?.label}</span>
            </div>
          </div>
        );
      },
    },
    {
      field: "policyStartDate",
      headerName: "Start Date",
      width: 230,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span>
            {date_time_format(row.policyStartDate, dateFormat, timeZone)}
          </span>
        );
      },
    },
    {
      field: "policyEndDate",
      headerName: "End Date",
      width: 230,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span>
            {date_time_format(row.policyEndDate, dateFormat, timeZone)}
          </span>
        );
      },
    },

    {
      field: "liveCounts",
      headerName: "No. of Dependants",
      width: 150,
      check_status: true,
    },
    {
      field: "employeeCount",
      headerName: "No. of Employees",
      width: 150,
      check_status: true,
    },
    {
      field: "sumInsured",
      headerName: "Sum Insured/Assured",
      width: 130,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return typeof row.sumInsured === "object"
          ? `${formatCurrency(row.sumInsured[0])}/...`
          : // row.sumInsured
            //   .map((item: any) => {
            //     return formatCurrency(item);
            //   })
            //   .join("/")
            formatCurrency(row.sumInsured);
      },
    },
    {
      field: "cdBalance",
      headerName: "CD Balance",
      width: 130,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{formatCurrency(row.cdBalance)}</span>;
      },
    },
    {
      field: "premium",
      headerName: "Premium",
      width: 130,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{formatCurrency(row.premium)}</span>;
      },
    },
    {
      field: "locations",
      headerName: "Locations",
      width: 130,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return row.locations.map((obj: any) => obj.name).join(", ");
      },
    },
  ]);
  const [showLoader, setShowLoader] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    get_policy(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    get_policy(tableLimit ?? 25, tableSkip ?? 0, "csv");
    handleClose();
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    }
  };
  useEffect(() => {
    //  get_policy();
    setShowLoader(true);
  }, []);

  useEffect(() => {
    searchBarFilter && get_policy();
  }, [searchBarFilter]);

  //   useEffect(() => {
  //     get_policy();
  // },[startEndDate])

  let timeoutIdOne: any;
  useEffect(() => {
    if (timeoutIdOne) {
      clearTimeout(timeoutIdOne);
    }

    const delayedApiCall = () => {
      get_policy();
    };

    timeoutIdOne = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutIdOne) {
        clearTimeout(timeoutIdOne);
      }
    };
  }, [startEndDate]);

  let timeoutId: any;
  // useEffect(() => {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   const delayedApiCall = () => {
  //     get_policy();
  //   };
  //   timeoutId = setTimeout(delayedApiCall, 500);
  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [searchBarFilter]);

  const get_policy = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }

      const data = res.data.data.data.map((e: any) => ({
        ...e,
        id: e._id,
        premium: Math.floor(Number(e?.premium)),
      }));

      setPolicyData(data);
      setTotalRecords(res.data.data.countData);
      if (csv) {
        downloadCsv(data);
      }
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    const currentRoute = E_BE_FILTER_CODE.POLICIES.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    EMPLOYER_POLICIES_SERVICES.get_policies_list(
      onSuccess,
      onError,
      limit ? limit : existingEntryIndex?.pageNumber ?? 25,
      skip ? skip : 0,
      startEndDate.startDate ?? "",
      startEndDate.endDate ?? "",
      searchBarFilter
    );
  };

  const downloadCsv = (DataCsv: any) => {
    const onSuccess = (res: any) => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "policies.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = DataCsv?.map((item: any) => {
      return {
        ...item,
        "Policy ID": item?.policyId,
        "Policy Status": item?.status?.label,
        "Client Name": item?.clientName,
        "Plan Type": item?.planType,
        "Client ID": item?.clientId,
        "Policy Type": item?.policyType,
        Insurer: item?.insurerDetails,
        "Policy Number": item?.policyNumber,
        "Enrollment Status": item?.enrolment_status?.label,
        "B.Type": item?.btype_status?.label,
        "No. of Dependants": item?.liveCounts,
        "No. of Employees": item?.employeeCount,
        "Start Date": date_time_format(
          item?.policyStartDate,
          dateFormat,
          timeZone
        ),
        "End Date": date_time_format(item?.policyEndDate, dateFormat, timeZone),
        "Created On": date_time_format(item?.createdAt, dateFormat, timeZone),
        "Sum Insured/Assured": formatSumInsured(
          item?.sumInsured,
          formatCurrency
        ),
        "CD Balance":
          item?.cdBalance !== "--" && item?.cdBalance !== undefined
            ? formatCurrency(item?.cdBalance)?.replace(/₹/g, "Rs")
            : item?.cdBalance,
        Premium:
          item?.premium !== "--" && item?.premium !== undefined
            ? formatCurrency(item?.premium)?.replace(/₹/g, "Rs")
            : item?.premium,
        "Updated On": date_time_format(item?.updatedAt, dateFormat, timeZone),
        Locations: item?.locations
          ?.map((label: any) => label?.name)
          ?.join(", "),
        "Enrolment Status": item?.enrolment_status?.label,
      };
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });
    let dto = {
      headers: columns,
      data: updatedArr,
    };
    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      usertypeinfo.toLowerCase(),
      "policies"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={3}>
        <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Policies</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid xs={6} className="text-right">
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {policyData?.length > 0 && downloadCsvPermission && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
        <Grid xs={12}>
          <UGTable
            header_data={columns}
            data={policyData}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.POLICIES}
            totalRecords={totalRecords}
            refetch_data={get_policy}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>
      </Grid>
    </>
  );
}
