import React, { useState, useEffect } from "react";
import SlidingPanel from "react-sliding-side-panel";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import { Box, Button, Link } from "@mui/material";
import DatePicker from "../../../Supporting files/DatePicker/DatePicker";
import {
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../Supporting files/HelpingFunction";
import { toast } from "react-toastify";
import { get_subindustry_list } from "../../../Supporting files/HelpingFunction";
import { get_industry_list } from "../../../Supporting files/HelpingFunction";
import SearchDropdown from "../../../Supporting files/SearchDropdown/SearchDropdown";
import { Claim_SERVICES } from "../../../Services/Claim/ClaimServices";
import { ENDORSEMENT_SERVICES } from "../../../Services/Endorsement/EndorsementService";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";
import { useAppSelector } from "../../../Store/hooks";
interface Props {
  open_status: boolean;
  value_update: Function;
  header_data?: any;
  selected_id: any;
  get_Api_url?: any;
  update_Api_url: any;
  shortcutDisable?: boolean, setShortcutDisable?: Function;
  status?: boolean;
  forComponent: string;
  forUserType?: string;
}

const MassUpdate: React.FC<Props> = ({
  open_status,
  value_update,
  get_Api_url,
  selected_id,
  update_Api_url,
  shortcutDisable, setShortcutDisable,
  status,
  forComponent,
  forUserType
}) => {
  const { userType } = useAppSelector((state) => state.userDetailsSlice);
  const [errorMsg, seterrorMsg] = useState<boolean>(false);
  const [errorMsg2, seterrorMsg2] = useState<boolean>(false);
  const [errorHeader, seterrorHeader] = useState<boolean>(false);
  const [validateEmailPhoneurl, setvalidateEmailPhoneurl] = useState<boolean>(false);
  const [header_list, setheader_list] = useState<Array<any>>([]);
  const [massUpdateData, setmassUpdateData] = useState({ headername: "", updatedvalue: "", });
  const [valueforIndustry, setvalueforIndustry] = useState({ Industry: "", "Sub-Industry": "", });
  const [subIndustryData, setSubIndustryData] = useState<Array<{ label: string; id: string }>>([]);
  const [IndustryData, setIndustryData] = useState<Array<{ label: string; id: string }>>([]);
  const [massUpdateList, setmassUpdateList] = useState<any>([]);
  const [colorCode, setcolorCode] = useState<string>("");
  const [totaldataMassModification, settotaldataMassModification] = useState<any>({});
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const navigate = useNavigate();
  const [reportOpen, setReportOpen] = useState(false);
  const [assignedResponse, setAssignedResponse] = useState<any>({
    errorData: [],
    successData: [],
  });
  const [errorFileStatus, seterrorFileStatus] = useState<string>("errorData");

  console.log("massUpdateList", massUpdateList)

  const cross_Icon = () => {
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
    setReportOpen(false)
    setvalueforIndustry({ Industry: "", "Sub-Industry": "" })
    value_update("massupdateopen", false);
    setmassUpdateData({
      headername: "",
      updatedvalue: "",
    });
    seterrorMsg(false);
    setvalidateEmailPhoneurl(false);
    setmassUpdateList([]);
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    Claim_SERVICES.get_status(onSuccess, onError);
  };
  const get_Endorsement_status = (forUserType?: string) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };
    forUserType ?
      ENDORSEMENT_SERVICES.get_Hr_status(onSuccess, onError)
      :
      ENDORSEMENT_SERVICES.get_status(onSuccess, onError)
  };

  const updateMaster = (attrName: any, value: any) => {
    let data = { ...massUpdateData };
    if (attrName[0] === "headerName") {
      seterrorHeader(false);
      seterrorMsg(false)
      data = { ...data, ["headername"]: value, ["updatedvalue"]: "" };
      let get_data: any
      if (forComponent === "Claim") {
        get_data = [...totaldataMassModification]
        get_status()
      }
      else if (forComponent === "Endorsement") {
        get_data = [...totaldataMassModification]
        get_Endorsement_status(forUserType);
      }
      else {
        get_data = totaldataMassModification?.filter((el: any) => {
          return el.field_lable === value;
        });
      }
      setmassUpdateList(get_data);
    } else if (attrName[0] === "massModificationKeyStatus") {
      data = { ...data, ["updatedvalue"]: value.value };
      setcolorCode(value.value.color);
      seterrorMsg(false);
    } else if (attrName[0] === "massModificationKeyforindustry") {
      let industrydata = { ...valueforIndustry };
      if (attrName[2] === "industry") {
        seterrorMsg(false);
        get_subindustry_list(value, (cb: any) => {
          setSubIndustryData(cb);
        });
        data = { ...data, ["updatedvalue"]: value && value.label };
        industrydata = {
          ...industrydata,
          ["Industry"]: value && value.label,
          ["Sub-Industry"]: "",
        };
      } else if (attrName[2] === "subindustry") {
        seterrorMsg2(false);
        industrydata = {
          ...industrydata,
          ["Sub-Industry"]: value && value.label,
        };
      }
      setvalueforIndustry(industrydata);
    } else if (attrName[0] === "massModificationKey") {
      data = { ...data, ["updatedvalue"]: value };
      seterrorMsg(false);
      if (
        massUpdateList[0].field_type === "number" ||
        massUpdateList[0].field_type === "phone"
      ) {
        setvalidateEmailPhoneurl(!validateMobileNumber(value));
        seterrorMsg(!validateMobileNumber(value));
      } else if (massUpdateList[0].field_type === "email") {
        setvalidateEmailPhoneurl(!validateEmail(value));
        seterrorMsg(!validateEmail(value));
      } else if (massUpdateList[0].field_type === "url") {
        setvalidateEmailPhoneurl(!validateUrl(value));
        seterrorMsg(!validateUrl(value));
      }
    } else if (attrName[0] === "claimStatus") {
      data = { ...data, ["updatedvalue"]: value.value };
      seterrorMsg(false);
    }
    else if (attrName[0] === "endorsementStatus") {

      data = { ...data, ["updatedvalue"]: value.value };

      console.log("after", data)
      seterrorMsg(false);
    }
    setmassUpdateData(data);
  };
  // creating dropdown data from array which we recived
  const get_header_list = () => {
    let arr: any = [];
    if (forComponent === "Claim") {
      arr.push({ key: "claimStatus", value: "status" })
    } else if (forComponent === "Endorsement") {
      arr.push({ key: "EndorsementStatus", value: "status" })
    }
    else {
      if (Array.isArray(totaldataMassModification)) {
        arr = totaldataMassModification.map((data) => ({
          key: data.field_lable,
          value: data.field_lable,
        }));
        arr = arr?.filter((dd: any) => dd.key !== "Id")?.filter((dd: any) => dd.key !== "Location")?.filter((dd: any) => dd.key !== "Office Phone Number")
        if (status) {
          arr = arr?.filter((dd: any) => dd.key !== "status")
        }
      }
    }
    console.log("arr", arr)
    setheader_list(arr);
  };
  //get data for the dropdown and fields
  const get_mass_updation_data = () => {
    if (forComponent === "Claim") {
      settotaldataMassModification([{ key: "claimStatus", value: "status" }])
    }
    else if (forComponent === "Endorsement") {

      settotaldataMassModification([{ key: "EndorsementStatus", value: "status" }])
    }
    else {
      const onSuccess = (res: any) => {
        settotaldataMassModification(res.data.data);
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      get_Api_url(onSuccess, onError);
    }

  };
  const downloadCsv = (CsvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "claimMassUpdate.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = CsvData.errorData?.map((item: any) => {
      return item;
    });
    let dto = {
      headers: ["claimId", "reason"],
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType?.toLowerCase(),
      "bulkAssignPolicy"
    );
  };
  //update mass data
  const updateMassmodification = () => {

    let keys_dynamic: any = massUpdateData.headername;
    console.log("key", massUpdateData)
    let value: any = massUpdateData.updatedvalue;
    const onSuccess = (res: any) => {
      console.log("res>>", res?.data?.data);
      if (forComponent === "Claim") {
        setAssignedResponse(res.data.data);

        return setReportOpen(true)
        //value_update("getClaimListMassUpdate", "");
        // window.location.reload();
        //navigate(ALL_ROUTES.CLIAM_ASSISTANT)

      }
      if (forComponent === "Endorsement") {

        navigate(ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS, { state: { data: res?.data?.data } });
      }
      toast.success("Updated Successfully");
      value_update("massupdateopen", false);
      setmassUpdateData({
        headername: "",
        updatedvalue: "",
      });
      setvalueforIndustry({ Industry: "", "Sub-Industry": "", });
      setmassUpdateList([]);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };

    const onError = (err: any) => {
      toast.error("Something went wrong");
      console.log("err", err);
    };

    update_Api_url(
      keys_dynamic === "status"
        ? {
          selectedId: selected_id,
          status: {
            name: value.value,
            color: colorCode,
          },
        }
        : keys_dynamic === "Industry"
          ? {
            selectedId: selected_id,
            data: valueforIndustry,
          }
          : keys_dynamic === "EndorsementStatus" ? { endorsementIds: selected_id, status: value } : {
            selectedId: selected_id,
            data: {
              [keys_dynamic]: value,
            },
          },

      onSuccess,
      onError
    );
  };

  // validate before api call
  const submitMassModification = () => {

    let err = errorMsg;
    let err1 = errorMsg2;
    let err2 = errorHeader;
    err2 = massUpdateData.headername ? false : true;
    if (err2) {
      return seterrorHeader(err2);
    }
    err = massUpdateData.updatedvalue ? false : true;
    if (massUpdateData.headername === "Industry") {
      err = valueforIndustry.Industry ? false : true;
      if (valueforIndustry.Industry) {
        err1 = valueforIndustry["Sub-Industry"] ? false : true;
      }
    }
    seterrorMsg(err);
    seterrorMsg2(err1)
    if (!err && !err1 && !validateEmailPhoneurl) {
      updateMassmodification();
    }
  };

  useEffect(() => {
    if (open_status) {
      get_header_list();
      get_mass_updation_data();
      get_industry_list((cb: any) => {
        setIndustryData(cb);
      });
    }

  }, [open_status,]);

  useEffect(() => {
    if (open_status) {
      get_header_list();

    }

  }, [open_status, totaldataMassModification]);
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
        backdropClicked={() => {
          setvalueforIndustry({ Industry: "", "Sub-Industry": "" })
          value_update("massupdateopen", false);
          setReportOpen(false)
          if (setShortcutDisable) {
            setShortcutDisable(false);
          }
        }}
      >
        <>
          {!reportOpen &&
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={10}>
                  <h4 className="mb-0 mt-3 text-left">Mass Update your Data</h4>
                </Grid>
                <Grid xs={2} display={'flex'} justifyContent={'end'} className="mb-0 mt-3">
                  <GridCloseIcon onClick={cross_Icon} />
                </Grid>
                <Grid xs={12} className="mb-4">
                  <hr />
                </Grid>
              </Grid>
              <div className="scrollable_area">
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Field you want to Update"
                      value={massUpdateData.headername}
                      attrName={["headerName", "dropdown"]}
                      value_update={updateMaster}
                      dropdown_data={header_list}
                      warn_status={errorHeader}
                      error_messg={`Select Field you want to Update`}
                    />
                  </Grid>
                  <Grid xs={6} className="mb-4">
                    {massUpdateList[0]?.field_type === "singleline" ? (
                      <RKTextField
                        class_name="inputField"
                        title={massUpdateList[0]?.field_lable}
                        value={massUpdateData.updatedvalue}
                        attrName={[
                          "massModificationKey",
                          massUpdateList[0]?.field_lable,
                        ]}
                        value_update={updateMaster}
                        warn_status={errorMsg}
                        error_messg={`Enter ${massUpdateList[0]?.field_lable}`}
                      />
                    ) : massUpdateList[0]?.field_type === "datepicker" ? (
                      <DatePicker
                        class_name="inputField"
                        title={massUpdateList[0]?.field_lable}
                        value={massUpdateData.updatedvalue}
                        attrName={[
                          "massModificationKey",
                          massUpdateList[0]?.field_lable,
                        ]}
                        value_update={updateMaster}
                        max_date={new Date()}
                        error_message={`Enter ${massUpdateList[0]?.field_lable}`}
                        warn_status={errorMsg}
                      />
                    ) : massUpdateList[0]?.field_type === "number" ||
                      massUpdateList[0]?.field_type === "phone" ? (
                      <RKTextField
                        class_name="inputField"
                        title={massUpdateList[0]?.field_lable}
                        value={massUpdateData.updatedvalue}
                        attrName={[
                          "massModificationKey",
                          massUpdateList[0]?.field_lable,
                        ]}
                        value_update={updateMaster}
                        validation_type="numeric"
                        warn_status={errorMsg || validateEmailPhoneurl}
                        charcterlimit={massUpdateList[0].characterLimit}
                        error_messg={massUpdateData.updatedvalue === "" ? `Enter ${massUpdateList[0]?.field_lable}` : `Enter valid ${massUpdateList[0]?.field_lable}`} //!validateMobileNumber(value)
                      />
                    ) : massUpdateList[0]?.field_type === "email" ? (
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={massUpdateList[0]?.field_lable}
                        value={massUpdateData.updatedvalue}
                        attrName={[
                          "massModificationKey",
                          massUpdateList[0]?.field_lable,
                        ]}
                        value_update={updateMaster}
                        warn_status={errorMsg}
                        error_messg={"Enter valid Email-ID"}
                      />
                    ) : massUpdateList[0]?.field_type === "industry" ? (
                      <>
                        <SearchDropdown
                          class_name="inputField"
                          title={massUpdateList[0]?.field_lable}
                          value={valueforIndustry.Industry}
                          attrName={[
                            "massModificationKeyforindustry",
                            massUpdateList[0]?.field_lable,
                            "industry",
                          ]}
                          value_update={updateMaster}
                          data={IndustryData}
                          warn_status={errorMsg}
                          value_type_id_value={false}
                          error_message={`Enter ${massUpdateList[0]?.field_lable}`}
                        />
                        {
                          valueforIndustry.Industry && (
                            <SearchDropdown
                              class_name="inputField mt-4"
                              title={"Sub-Industry"}
                              value={valueforIndustry["Sub-Industry"]}
                              attrName={[
                                "massModificationKeyforindustry",
                                massUpdateList[0]?.field_lable,
                                "subindustry",
                              ]}
                              value_update={updateMaster}
                              data={subIndustryData}
                              warn_status={errorMsg2}
                              error_message={`Enter Sub-Industry`}
                            />
                          )
                        }

                      </>
                    ) : massUpdateList[0]?.field_type === "singleselect" ? (
                      <SelectDropdown
                        class_name="inputField"
                        title={massUpdateList[0]?.field_lable}
                        value={massUpdateData.updatedvalue}
                        attrName={[
                          "massModificationKey",
                          massUpdateList[0]?.field_lable,
                        ]}
                        value_update={updateMaster}
                        dropdown_data={massUpdateList[0]?.pickliststep}
                        warn_status={errorMsg || validateEmailPhoneurl}
                        error_messg={`Select ${massUpdateList[0]?.field_lable}`}
                      />
                    ) : massUpdateList[0]?.field_type === "statusselect" ? (
                      <>
                        <SelectDropdown
                          class_name="inputField"
                          title={massUpdateList[0]?.field_lable}
                          value={massUpdateData.updatedvalue}
                          attrName={[
                            "massModificationKeyStatus",
                            massUpdateList[0]?.field_lable,
                          ]}
                          value_update={updateMaster}
                          dropdown_data={massUpdateList[0]?.pickliststep}
                          warn_status={errorMsg || validateEmailPhoneurl}
                          value_type_id_value={true}
                          error_messg={`Enter ${massUpdateList[0]?.field_lable}`}
                        />
                      </>
                    ) : massUpdateList[0]?.key === "claimStatus" ?
                      <>
                        <SelectDropdown
                          class_name="inputField"
                          title={"status"}
                          value={massUpdateData.updatedvalue}
                          attrName={[
                            "claimStatus",
                            massUpdateList[0]?.key,
                          ]}
                          value_update={updateMaster}
                          dropdown_data={prioritystatusData}
                          warn_status={errorMsg || validateEmailPhoneurl}
                          value_type_id_value={true}
                          error_messg={`Enter ${massUpdateList[0]?.key}`}
                        />
                      </>
                      : massUpdateList[0]?.key === "EndorsementStatus" ?
                        <SelectDropdown
                          class_name="inputField"
                          title={"status"}
                          value={massUpdateData.updatedvalue}
                          attrName={[
                            "endorsementStatus",
                            massUpdateList[0]?.key,
                          ]}
                          value_update={updateMaster}
                          dropdown_data={prioritystatusData}
                          warn_status={errorMsg || validateEmailPhoneurl}
                          value_type_id_value={true}
                          error_messg={`Enter ${massUpdateList[0]?.key}`}
                        /> : null}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
                    <Button
                      onClick={submitMassModification}
                      variant="contained"
                      className="submitBtn"
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>}
          {reportOpen && forComponent === "Claim" && (
            <Box className="BulklayoutWrapper" style={{ paddingTop: "10px" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={10}>
                  <h4 className="mb-0 mt-3 text-left">Mass Update your Data</h4>
                </Grid>
                <Grid xs={2} display={"flex"} justifyContent={"end"} className="mb-0 mt-3">
                  <GridCloseIcon onClick={cross_Icon} />
                </Grid>
                <Grid xs={12} className="mb-0">
                  <hr />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid xs={12} className="innerSection pl-10 mt-4">
                  <Grid container spacing={3}>
                    <Grid xs={12} className="mapfields_instructions mt-4 mb-4">
                      <Grid container spacing={3} padding="0" className="mt-3 ml-3">
                        <Grid xs={8} textAlign={"center"}>
                          <ul className="recordViewList">
                            <li className="black_li">
                              Total Members: <span>{10}</span>
                            </li>
                            <li
                              style={
                                "successData" === "successData"
                                  ? {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }
                                  : { cursor: "pointer" }
                              }
                            >
                              Added: {assignedResponse?.successData?.length}
                            </li>
                            <li
                              onClick={() => {
                                seterrorFileStatus("errorData");
                              }}
                              style={
                                errorFileStatus === "errorData"
                                  ? {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }
                                  : { cursor: "pointer" }
                              }
                            >
                              Skipped: <span>{assignedResponse?.errorData?.length}</span>
                            </li>
                            <li>
                              <Link
                                underline="none"
                                className="blueBtn download"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  downloadCsv(assignedResponse);
                                }}
                              >
                                Download CSV
                              </Link>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={12} padding="0">
                      {/* Scrollable Section */}
                      <div
                        className="table"
                        style={{
                          overflowY: "auto", // Enable vertical scrolling
                          maxHeight: "500px", // Adjust height as needed
                          border: "1px solid #ccc", // Optional: add a border
                          padding: "10px", // Optional: add padding
                        }}
                      >
                        <div
                          className="renewal-table2"
                          style={{ overflow: "visible" }}
                        >
                          <ul
                            className="heading"
                            style={{ justifyContent: "start" }}
                          >
                            <li>
                              <p>Claim ID</p>
                            </li>
                            <li>
                              <p>Status/Reason</p>
                            </li>
                          </ul>
                          {assignedResponse[errorFileStatus]?.length > 0 &&
                            assignedResponse[errorFileStatus]?.map(
                              (data: any, index: number) => (
                                <ul
                                  key={index}
                                  className="subheading"
                                  style={{ justifyContent: "start" }}
                                >
                                  <li>
                                    <p className="record_p">{data.claimId}</p>
                                  </li>
                                  <li>
                                    <p
                                      className="record_p"
                                      style={{ width: "500px" }}
                                    >
                                      {errorFileStatus === "successData"
                                        ? "Policy has been assigned Successfully"
                                        : data.reason}
                                    </p>
                                  </li>
                                </ul>
                              )
                            )}
                        </div>
                      </div>

                      {/* No Records */}
                      {assignedResponse[errorFileStatus]?.length === 0 && (
                        <div className="no_records" style={{ background: "none" }}>
                          <span>No Records Available</span>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      </SlidingPanel>
    </>
  );
};

export default MassUpdate;
